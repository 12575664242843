import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';

interface ICardProps {
    messages: {
        firstName: string;
        lastName: string;
        cardNumber: string;
        expire: string;
        code: string;
    };
}

interface IBankProps {
    method: 'manual' | 'instant';
    messages: {
        accountName: string;
        transitNumber: string;
        accountNumber: string;
        bankNumber: string;
    };
}

export const linkCardValidation = ({ messages }: ICardProps) =>
    z.object({
        firstName: validator.firstName(messages.firstName),
        lastName: validator.lastName(messages.lastName),
        cardNumber: validator.cardNumber(messages.cardNumber),
        expire: validator.cardExpiration(messages.expire),
        code: validator.cardCVV(messages.code)
    });

export const linkBankValidation = ({ messages, method }: IBankProps) => {
    if (method === 'manual') {
        return z.object({
            accountNumber: validator.accountNumber(messages.accountNumber),
            transitNumber: z.string().min(1, messages.transitNumber),
            accountName: z.string().min(1, messages.accountName),
            bankNumber: validator.accountType(messages.bankNumber)
        });
    } else {
        return z.object({});
    }
};
