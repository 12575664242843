import { modalIds } from '@const/modals';
import { apiAccountService } from '@api';
import useModalContext from '@hooks/context/useModalContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';

export const useInteracModals = () => {
    const { onClose, onOpen, handleChangeLoading } = useModalContext();
    const { refetchAccount } = useUserContext();
    const { requestSuccessLocale, requestErrorLocale } = useLocales();

    const handleChangeInteracEmail = () => {
        onOpen({
            modalId: modalIds.UPDATE_INTERAC,
            onSubmit: ({ interacEmail }: { interacEmail: string }) => {
                handleChangeLoading(true);
                apiAccountService
                    .updateInteracEmail(interacEmail)
                    .then(() => {
                        requestSuccessLocale('update_interac');
                        refetchAccount();
                        onClose();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    return {
        handleChangeInteracEmail
    };
};
