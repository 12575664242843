import React, { FC, useMemo } from 'react';
import ChooseMethod from '@sections/send_and_request/choose_method';
import { ESendRequestMoneyMethod } from '@enum';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    onChangeMethod: (val: ESendRequestMoneyMethod) => void;
    method: ESendRequestMoneyMethod;
    onSubmit: () => void;
    type: 'request' | 'send';
    styles: any;
    title: string;
}

const requestMethods = [
    ESendRequestMoneyMethod.INTERNAL_REQUEST,
    ESendRequestMoneyMethod.E_TRANSFER_REQUEST,
    ESendRequestMoneyMethod.BANK_REQUEST
];

const sendMethods = [
    ESendRequestMoneyMethod.INTERNAL,
    ESendRequestMoneyMethod.E_TRANSFER,
    ESendRequestMoneyMethod.BANK
];

const ChooseMethodSection: FC<IProps> = ({
    onChangeMethod,
    method,
    onSubmit,
    type,
    title,
    styles
}) => {
    const { submitLocale } = useLocales();

    const methods = useMemo(() => (type === 'request' ? requestMethods : sendMethods), [type]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{title}</Typography>
                </div>
                <div className={styles.cards_wrapper}>
                    {methods.map((item, i) => (
                        <ChooseMethod
                            key={i}
                            checked={method === item}
                            onClick={() => onChangeMethod(item)}
                            type={item}
                        />
                    ))}
                    <Button variant="primary" size="normal" onClick={onSubmit}>
                        {submitLocale('next')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ChooseMethodSection;
