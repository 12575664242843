import React, { useEffect } from 'react';
import Typography from '@components/common/typography';
import styles from '../profile/styles.module.scss';
import { useUserContext } from '@hooks/context/useUserContext';
import { useForm } from '@hooks/helpers/useForm';
import { defaultBusinessData } from '@const/default_form_data';
import { FormProvider } from 'react-hook-form';
import AddressSection from '@sections/settings/address';
import { zodResolver } from '@hookform/resolvers/zod';
import { businessSettingsValidation } from '@helpers/validation_objects/profile_settings';
import { EAddressType, EUserType, TBusinessAddress } from '@xeppt/xeppt-sdk/types/user';
import { TBusinessDetails } from '@types';
import BusinessSection from '@sections/settings/business';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiUserService } from '@api';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import AvatarSection from '@sections/settings/avatar';

const BusinessLayout = () => {
    const { t } = useTranslation('settings', {
        keyPrefix: 'businessProfile'
    });
    const navigate = useNavigate();
    const { validationLocale, labelLocale } = useLocales();
    const { user, businessData } = useUserContext();
    const formMethods = useForm<TBusinessDetails>({
        defaultValues: defaultBusinessData,
        resolver: zodResolver(
            businessSettingsValidation({
                messages: {
                    companyName: validationLocale('companyName'),
                    tradingName: validationLocale('tradingName'),
                    registrationNumber: validationLocale('registrationNumber'),
                    category: validationLocale('category'),
                    size: validationLocale('size'),
                    website: validationLocale('website')
                }
            })
        )
    });

    useEffect(() => {
        if (user?.type === EUserType.BUSINESS) {
            formMethods.reset(businessData);
        } else {
            navigate(routes.settings);
        }
    }, [user]);

    const { data } = useApiQuery({
        method: () => apiUserService.getAddress()
    });

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <FormProvider {...formMethods}>
                <div className={styles.content}>
                    <AvatarSection type="business" />
                    <BusinessSection />
                    <AddressSection
                        label={labelLocale('registrationAddress')}
                        name="registrationAddress"
                        type={EAddressType.REGISTRATION}
                        data={data as TBusinessAddress}
                    />
                    <AddressSection
                        label={labelLocale('tradingAddress')}
                        name="tradingAddress"
                        type={EAddressType.TRADING}
                        data={data as TBusinessAddress}
                    />
                    <AddressSection
                        label={labelLocale('businessAddress')}
                        name="businessAddress"
                        type={EAddressType.BUSINESS}
                        data={data as TBusinessAddress}
                    />
                </div>
            </FormProvider>
        </motion.div>
    );
};

export default BusinessLayout;
