import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useLocales } from '@hooks/helpers/useLocales';
import Address from '@components/address';
import Input from '@components/common/input';
import Select from '@components/common/select';
import FormField from '@components/form_field';
import { useFormContext, useWatch } from 'react-hook-form';
import { ECardType, TCardLite } from '@xeppt/xeppt-sdk/types/card';
import { ECardStatus } from '@xeppt/xeppt-sdk/types';
import { hideCardNumber } from '@utils/index';

interface IProps {
    cards: TCardLite[];
}

const ThirdStep: FC<IProps> = ({ cards }) => {
    const { labelLocale, validationLocale } = useLocales();
    const form = useFormContext();
    const watchedCardType = useWatch({ name: 'type', control: form.control });

    return (
        <div className={styles.wrapper}>
            <div className={styles.column}>
                {watchedCardType === ECardType.PHYSICAL_SUPPLEMENTARY && (
                    <FormField<string | number>
                        name="cardId"
                        renderComponent={(props) => {
                            return (
                                <Select
                                    {...props}
                                    full
                                    label={labelLocale('primary_card')}
                                    items={
                                        cards
                                            ?.filter(
                                                (item) =>
                                                    item.type === ECardType.PHYSICAL &&
                                                    item.status === ECardStatus.ACTIVE
                                            )
                                            .map((item) => ({
                                                value: item.id,
                                                label: `${item.cardHolder} ${hideCardNumber(item.cardNumber)} $${item.balance}CAD`
                                            })) || []
                                    }
                                />
                            );
                        }}
                        rules={{
                            required: {
                                value: true,
                                message: validationLocale('main_card')
                            }
                        }}
                    />
                )}
                <div className={styles.row}>
                    <FormField
                        name="firstName"
                        renderComponent={(props) => (
                            <Input {...props} full label={labelLocale('first_name')} />
                        )}
                    />
                    <FormField
                        name="lastName"
                        renderComponent={(props) => (
                            <Input {...props} full label={labelLocale('last_name')} />
                        )}
                    />
                </div>
                {/*<DatePicker*/}
                {/*    readOnly*/}
                {/*    value={user?.profile.birthDate as string | undefined}*/}
                {/*    full*/}
                {/*    label={labelLocale('birth_date')}*/}
                {/*/>*/}
                {/*<Input value={user?.profile} full label={labelLocale('occupation')} />*/}
            </div>
            <Typography variant="body1">{labelLocale('shipping_address')}</Typography>
            <Address name="shippingAddress" />
        </div>
    );
};

export default ThirdStep;
