import React, { useState } from 'react';
import styles from '@layouts/settings/profile/styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import FormField from '@components/form_field';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import Contacts from '@sections/settings/contacts';
import { useFormContext } from 'react-hook-form';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiUserService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';
import { EUserStatus } from '@xeppt/xeppt-sdk/types';
import Button from '@components/common/button';

const BusinessSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'settings.business'
    });
    const { labelLocale, submitLocale, requestSuccessLocale, requestErrorLocale } = useLocales();
    const form = useFormContext();
    const [isEdit, setIsEdit] = useState(false);
    const { refetchUser, user } = useUserContext();

    const { handleRequest: handleUpdateUser } = useApiMutation({
        method: () => {
            const { companyName, tradingName, registrationNumber, category, size, website } =
                form.getValues();
            return apiUserService.updateProfile({
                companyName,
                tradingName,
                registrationNumber,
                category,
                size,
                website
            });
        },
        onSuccess: () => {
            requestSuccessLocale('update_business');
            refetchUser();
            setIsEdit(false);
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        if (!isEdit) {
            setIsEdit(true);
        } else {
            form.trigger().then((isValid) => {
                isValid && handleUpdateUser(undefined);
            });
        }
    };

    return (
        <div className={styles.row}>
            <div className={styles.header}>
                <Typography variant="body1" lh="120%" weight="semibold">
                    {t('title')}
                </Typography>
                {user?.status === EUserStatus.UNVERIFIED && (
                    <Button
                        leftIcon={isEdit ? undefined : 'edit'}
                        variant="outlined"
                        size="normal"
                        onClick={onSubmit}>
                        {isEdit ? submitLocale('save') : submitLocale('edit')}
                    </Button>
                )}
            </div>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="companyName"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('company_name')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="tradingName"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('trading_name')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="registrationNumber"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('registration_number')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="category"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('category')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="size"
                        renderComponent={(props) => (
                            <Input label={labelLocale('size')} {...props} full readOnly={!isEdit} />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="website"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('website')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <Contacts />
            </div>
        </div>
    );
};

export default BusinessSection;
