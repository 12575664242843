import React from 'react';
import illustration from '@svg/illustrations/404.svg';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import styles from './styles.module.scss';

const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <img src={illustration} alt="page not found" />
            <Typography className={styles.big_text}>404</Typography>
            <Typography variant="body1">Sorry, page not found</Typography>
            <Button
                className={styles.button}
                variant="primary"
                size="normal"
                onClick={() => navigate(routes.dashboard)}>
                Back to Home
            </Button>
        </div>
    );
};

export default NotFoundPage;
