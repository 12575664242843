import React, { FC, Fragment, useMemo, useRef, useState } from 'react';
import Input from '@components/common/input';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import useClickOutside from '@hooks/helpers/useClickOutside';
import Typography from '@components/common/typography';
import Button from '@components/common/button';

type TOption = {
    value: string | number;
    label: string;
};

interface IProps {
    value?: string | number;
    onChange?: (value: string | number) => void;
    onChangeSearch: (value: string) => void;
    search: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    name?: string;
    error?: boolean;
    items?: TOption[];
    full?: boolean;
    isButton?: boolean;
    buttonLabel?: string;
    onButtonClick?: (value: string | number) => void;
    disabled?: boolean;
    splitArrays?: {
        label: string;
        array: TOption[];
    }[];
}

const cx = classNames.bind(styles);

const SearchSelect: FC<IProps> = ({
    value = '',
    placeholder,
    disabled,
    full,
    label,
    items,
    onButtonClick,
    error,
    splitArrays,
    onChange,
    onChangeSearch,
    search,
    name,
    isButton,
    buttonLabel,
    helperText
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    const handleChange = (value: string | number) => {
        if (onChange) {
            onChange(value);
            onChangeSearch('');
        }
        setIsOpen(false);
    };

    useClickOutside({
        ref,
        callback: () => setIsOpen(false),
        event: 'mousedown'
    });

    const foundSplitValue = useMemo(() => {
        let foundItem: TOption = {
            value: '',
            label: ''
        };
        splitArrays?.forEach((split) => {
            const splitFoundItem = split.array.find((item) => item.value === value);

            if (splitFoundItem) {
                foundItem = splitFoundItem;
            }
        });
        return foundItem;
    }, [splitArrays, value]);

    const foundItemsValue = useMemo(() => {
        return items?.find((item) => item.value === value);
    }, [items, value]);

    return (
        <div className={cx([styles.wrapper, { isFull: full }])} ref={ref}>
            <Input
                rightIcon="search"
                value={search || foundSplitValue?.label || foundItemsValue?.label}
                placeholder={placeholder}
                label={label}
                full={full}
                name={name}
                className={cx([{ isOpen }])}
                onFocus={() => setIsOpen(true)}
                onClickIcon={() => setIsOpen((state) => !state)}
                disabled={disabled}
                error={error}
                onChange={(val) => {
                    onChangeSearch(val);
                }}
                helperText={helperText}
            />
            {((items?.length || 0) > 0 || splitArrays?.find((item) => item.array.length > 0)) && (
                <div className={cx([styles.dropdown, { isOpen }])}>
                    {items?.map((item, i) => (
                        <div
                            className={styles.item_wrapper}
                            key={i}
                            onClick={() => {
                                if (!disabled) handleChange(item.value);
                            }}>
                            {item.label}
                            {isButton && (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => onButtonClick && onButtonClick(item.value)}>
                                    {buttonLabel}
                                </Button>
                            )}
                        </div>
                    ))}
                    {splitArrays?.map((item, i) => {
                        if (item.array.length > 0) {
                            return (
                                <Fragment key={i}>
                                    <Typography
                                        variant="body3"
                                        fw={500}
                                        className={styles.array_label}>
                                        {item.label}
                                    </Typography>
                                    {item.array?.map((item, i) => (
                                        <div
                                            className={styles.item_wrapper}
                                            key={i}
                                            onClick={() => {
                                                if (!disabled) handleChange(item.value);
                                            }}>
                                            {item.label}
                                            {isButton && (
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() =>
                                                        onButtonClick && onButtonClick(item.value)
                                                    }>
                                                    {buttonLabel}
                                                </Button>
                                            )}
                                        </div>
                                    ))}
                                </Fragment>
                            );
                        }
                        return null;
                    })}
                </div>
            )}
        </div>
    );
};

export default SearchSelect;
