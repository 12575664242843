import { modalIds } from '@const/modals';
import useModalContext from '@hooks/context/useModalContext';

export const useAccountsModals = () => {
    const { onOpen } = useModalContext();

    const handleOpenLinkCardOrBankModal = () => {
        onOpen({
            modalId: modalIds.LINK_BANK_OR_CARD
        });
    };

    return {
        handleOpenLinkCardOrBankModal
    };
};
