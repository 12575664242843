import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import MainSection from './main';
import AmountSection from './amount';
import SummarySection from './summary';
import SuccessSection from './success';
import Button from '@components/common/button';
import useModalContext from '@hooks/context/useModalContext';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';

const CashbackModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'cashback'
    });
    const { submitLocale } = useLocales();
    const { onClose, modalData: card } = useModalContext();
    const [step, setStep] = useState(1);
    const [type, setType] = useState<'pay' | 'redeem'>('pay');

    const renderContent = () => {
        switch (step) {
            case 1:
                return <MainSection type={type} onTypeChange={(val) => setType(val)} />;
            case 2:
                return <AmountSection type={type} />;
            case 3:
                return <SummarySection type={type} card={card} />;
            case 4:
                return <SuccessSection type={type} />;
        }
    };

    useEffect(() => {
        if (step === 0) {
            onClose();
        }
    }, [step]);

    const renderTitle = () => {
        if (step === 1) {
            return t('title');
        } else if (type === 'pay') {
            return t('title_pay');
        } else {
            return t('title_redeem');
        }
    };

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" className={styles.title}>
                {renderTitle()}
            </Typography>
            {renderContent()}
            <div className={styles.actions}>
                {step === 4 ? (
                    <>
                        <Button variant="primary" size="normal" onClick={onClose}>
                            {submitLocale('done')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            variant="outlined-dark"
                            size="normal"
                            onClick={() => setStep((state) => state - 1)}>
                            {submitLocale(step === 1 ? 'cancel' : 'back')}
                        </Button>
                        <Button
                            variant="primary"
                            size="normal"
                            onClick={() => setStep((state) => state + 1)}>
                            {submitLocale('next')}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default CashbackModal;
