import { useEffect, useState } from 'react';
import { getLastPaymentDate, getNumberOfPayments } from '@utils/billing';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import moment from 'moment-timezone';
import { useFormContext, useWatch } from 'react-hook-form';
import { EPaymentFrequency } from '@xeppt/xeppt-sdk/types/billing';
import { useUserContext } from '@hooks/context/useUserContext';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    isFrequencyExist?: boolean;
    method?: ESendRequestMoneyMethod;
}

export const useFrequency = ({ isFrequencyExist, method }: IProps) => {
    const form = useFormContext();
    const { account } = useUserContext();
    const { validationLocale } = useLocales();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control: form.control });
    const watchedAmount = useWatch({ name: 'amount', control: form.control });
    const watchedNextPaymentAt = useWatch({ name: 'nextPaymentAt', control: form.control });
    const watchedNumberOfPayments = useWatch({ name: 'numberOfPayments', control: form.control });
    const watchedPaymentsEndDate = useWatch({
        name: 'paymentsEndDate',
        control: form.control
    });
    const watchedPaymentType = useWatch({ name: 'paymentType', control: form.control });

    useEffect(() => {
        if (isFrequencyExist) {
            const resultNumberOfPayments = getNumberOfPayments({
                frequency: watchedFrequency,
                nextPaymentDate: watchedNextPaymentAt,
                lastPaymentDate: watchedPaymentsEndDate
            });
            if (
                watchedNumberOfPayments !== resultNumberOfPayments &&
                watchedPaymentType === ESchedulePaymentType.PAYMENTS_DATE
            ) {
                form.setValue(
                    'numberOfPayments',
                    resultNumberOfPayments > 0 ? resultNumberOfPayments : 1
                );
            }
        }
    }, [watchedPaymentsEndDate, watchedFrequency, isFrequencyExist, watchedNextPaymentAt]);

    useEffect(() => {
        if (isFrequencyExist) {
            const resultLastPaymentDate = getLastPaymentDate({
                frequency: watchedFrequency,
                nextPaymentDate: watchedNextPaymentAt,
                numberOfPayments: watchedNumberOfPayments
            });
            if (
                moment(watchedPaymentsEndDate).format('YYYY-MM-DD') !==
                    moment(resultLastPaymentDate).format('YYYY-MM-DD') &&
                watchedPaymentType === ESchedulePaymentType.NUMBER_OF_PAYMENTS
            ) {
                form.setValue('paymentsEndDate', resultLastPaymentDate);
            }
        }
    }, [watchedNumberOfPayments, watchedFrequency, isFrequencyExist, watchedNextPaymentAt]);

    useEffect(() => {
        if (isFrequencyExist) {
            if (!isFirstRender) {
                watchedAmount && Number(watchedAmount) !== 0 && form.trigger('amount');
            } else {
                setIsFirstRender(false);
            }
        }
    }, [watchedNextPaymentAt, watchedFrequency, isFrequencyExist]);

    useEffect(() => {
        if (isFrequencyExist) {
            const resultLastPaymentDate = getLastPaymentDate({
                frequency: watchedFrequency,
                nextPaymentDate: watchedNextPaymentAt,
                numberOfPayments: watchedNumberOfPayments
            });
            const resultNumberOfPayments = getNumberOfPayments({
                frequency: watchedFrequency,
                nextPaymentDate: watchedNextPaymentAt,
                lastPaymentDate: watchedPaymentsEndDate
            });
            form.setValue('paymentsEndDate', resultLastPaymentDate);
            form.setValue(
                'numberOfPayments',
                resultNumberOfPayments > 0 ? resultNumberOfPayments : 1
            );
        }
    }, [watchedNextPaymentAt]);

    const amountSendMoneyValidation = (amount: number) => {
        if (method === ESendRequestMoneyMethod.E_TRANSFER) {
            if (
                watchedFrequency === EPaymentFrequency.ONCE ||
                moment(watchedNextPaymentAt).isSame(moment(), 'day')
            ) {
                if (Number(amount) > (account?.balance || 0)) {
                    return validationLocale('not_enaught_balance');
                } else if (Number(amount) > (account?.eTransferAccount?.dailyLimit || 0)) {
                    return validationLocale('not_enaught_daily_limit');
                }
            }
        } else {
            if (
                watchedFrequency === EPaymentFrequency.ONCE ||
                moment(watchedNextPaymentAt).isSame(moment(), 'day')
            ) {
                if (amount > (account?.balance || 0)) {
                    return validationLocale('not_enaught_balance');
                }
            }
        }
    };

    return {
        amountSendMoneyValidation
    };
};
