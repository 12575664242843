import React, { FC, SVGAttributes } from 'react';

const Schedule: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.05625 7.4375C6.94375 7.06875 6.40625 6.8375 6.40625 6.25C6.40625 5.6125 7.1 5.38125 7.5375 5.38125C8.35625 5.38125 8.65625 6 8.725 6.21875L9.7125 5.8C9.61875 5.51875 9.2 4.6 8.125 4.4V3.625H6.875V4.4125C5.325 4.7625 5.31875 6.2 5.31875 6.2625C5.31875 7.68125 6.725 8.08125 7.4125 8.33125C8.4 8.68125 8.8375 9 8.8375 9.6C8.8375 10.3063 8.18125 10.6062 7.6 10.6062C6.4625 10.6062 6.1375 9.4375 6.1 9.3L5.0625 9.71875C5.45625 11.0875 6.4875 11.4562 6.875 11.5687V12.375H8.125V11.6C8.375 11.5437 9.9375 11.2312 9.9375 9.5875C9.9375 8.71875 9.55625 7.95625 8.05625 7.4375ZM1.875 13.625H0.625V9.875H4.375V11.125H2.825C3.83125 12.6313 5.55 13.625 7.5 13.625C10.6062 13.625 13.125 11.1062 13.125 8H14.375C14.375 11.8 11.3 14.875 7.5 14.875C5.175 14.875 3.11875 13.7188 1.875 11.9563V13.625ZM0.625 8C0.625 4.2 3.7 1.125 7.5 1.125C9.825 1.125 11.8813 2.28125 13.125 4.04375V2.375H14.375V6.125H10.625V4.875H12.175C11.1687 3.36875 9.45 2.375 7.5 2.375C4.39375 2.375 1.875 4.89375 1.875 8H0.625Z"
                fill="#282828"
            />
        </svg>
    );
};

export default Schedule;
