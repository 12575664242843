import React from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import Status from '@components/common/status';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';
import Skeleton from '@components/common/skeleton';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';

const AccountsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'manage_accounts.accounts'
    });
    const { account, isDataLoading } = useUserContext();
    const navigate = useNavigate();

    return (
        <>
            <div className={styles.wrapper}>
                <Typography variant="h4">{t('linked_accounts')}</Typography>
                <div className={styles.divider} />
                <div className={styles.accounts}>
                    {isDataLoading ? (
                        <Skeleton className={styles.skeleton} />
                    ) : !![account?.bankAccounts].length ? (
                        account?.bankAccounts?.map((item) => {
                            return (
                                <div
                                    className={styles.item}
                                    key={item.id}
                                    onClick={() =>
                                        navigate(`${routes.manage_accounts}?id=${item.id}`)
                                    }>
                                    <div className={styles.data}>
                                        <Icon name="credit_card" />
                                        <Typography>{item.institutionName}</Typography>
                                        <Typography>
                                            {item.accountName} {item.accountNumber}
                                        </Typography>
                                    </div>
                                    {item.isPrimary && (
                                        <Status variant="success">{t('preferred')}</Status>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <Typography variant="body1">{t('empty')}</Typography>
                    )}
                </div>
            </div>
        </>
    );
};

export default AccountsSection;
