import React from 'react';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import { useUserContext } from '@hooks/context/useUserContext';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import Skeleton from '@components/common/skeleton';
import { useAccountsModals } from '@hooks/modals/useAccountsModals';

const AccountsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.accounts'
    });
    const navigate = useNavigate();
    const { account, isDataLoading } = useUserContext();
    const { handleOpenLinkCardOrBankModal } = useAccountsModals();
    const { verifiedAction } = useVerificationGuard();

    const handleOpenModal = () => {
        if (!!account?.bankAccounts.length) {
            verifiedAction(() => navigate(routes.link_card_bank));
        } else {
            verifiedAction(handleOpenLinkCardOrBankModal);
        }
    };

    return (
        <div className={styles.wrapper}>
            <Button
                onClick={() => verifiedAction(() => navigate(routes.send_and_request))}
                className={styles.add_button}
                variant="primary"
                size="medium">
                {t('add_money')}
            </Button>
            <div className={styles.accounts}>
                <Typography variant="body1" weight="bold">
                    {t('title')}
                </Typography>
                {isDataLoading && <Skeleton className={styles.account_skeleton} />}
                {!!account?.bankAccounts.length && (
                    <ul className={styles.accounts_list}>
                        {account?.bankAccounts.map((item) => (
                            <li
                                key={item.id}
                                className={styles.account_item}
                                onClick={() => navigate(`${routes.manage_accounts}?id=${item.id}`)}>
                                <Typography variant="body3">{item.institutionName}</Typography>
                                <Typography variant="body3" fz={14}>
                                    {item.accountName} {item.accountNumber}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                )}
                <Button className={styles.add_card} onClick={handleOpenModal}>
                    <div>
                        <Icon name="plus" />
                    </div>
                    <Typography variant="h5" weight="bold">
                        {t('link_new')}
                    </Typography>
                </Button>
            </div>
            {/*<div className={styles.accounts}>*/}
            {/*    <Typography variant="body1" weight="bold">*/}
            {/*        {t('interac_title')}*/}
            {/*    </Typography>*/}
            {/*    {isDataLoading && <Skeleton className={styles.etransfer_skeleton} />}*/}
            {/*    <ul className={styles.accounts_list}>*/}
            {/*        <li className={styles.account_item}>*/}
            {/*            <Typography variant="body3">*/}
            {/*                {account?.eTransferAccount.interacEmail}*/}
            {/*            </Typography>*/}
            {/*            <Typography variant="body3" fz={14}>*/}
            {/*                {user?.profile.firstName}*/}
            {/*            </Typography>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <Button className={styles.add_card} onClick={handleChangeInteracEmail}>*/}
            {/*        <div>*/}
            {/*            <Icon name="edit" />*/}
            {/*        </div>*/}
            {/*        <Typography variant="h5" weight="bold">*/}
            {/*            {t('update_interac')}*/}
            {/*        </Typography>*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    );
};

export default AccountsSection;
