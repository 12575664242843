import { EEventName, EUserStatus } from '@xeppt/xeppt-sdk/types';
import { notificationsByType, notificationsGroups } from '@const/notifications';
import { TNotification } from '@xeppt/xeppt-sdk/types/notification';
import { getNotificationString } from '@utils/index';
import { NavigateFunction } from 'react-router-dom';
import { routes } from '@const/routes';
import { ENewNotificationType, ENotificationCategory } from '@enum';
import { prettifyDate } from '@utils/date';
import { TIconType } from '@components/icons';

export const getNotificationTypeByEvent = (event: EEventName) => {
    for (const [type, events] of Object.entries(notificationsByType)) {
        if (events.includes(event)) {
            return type as ENewNotificationType;
        }
    }
    return undefined; //
};

export const getNotificationGroupByEvent = (event: EEventName) => {
    for (const [type, events] of Object.entries(notificationsGroups)) {
        // @ts-ignore
        if (events.includes(event)) {
            return type as ENotificationCategory;
        }
    }
    return undefined; //
};

export const getNotificationAction = (
    notification: TNotification,
    navigate: NavigateFunction,
    handleGetVerificationResult: (codes: string[], status: EUserStatus) => void
) => {
    switch (notification.event) {
        case EEventName.UserVerified:
            return handleGetVerificationResult(
                (notification?.payload?.codes as string)?.split(',').filter(Boolean) || [],
                notification?.payload?.status as EUserStatus
            );
        case EEventName.EftAccountLinked:
            return (
                notification.payload?.id &&
                navigate(`${routes.manage_accounts}?id=${notification.payload?.id}`)
            );
        case EEventName.PaymentReceived:
            return (
                notification.payload?.id &&
                navigate(`${routes.transactions}/${notification.payload?.id}`)
            );
        case EEventName.BillPaymentProcesses:
            return (
                notification.payload?.id &&
                navigate(`${routes.transactions}/${notification.payload?.id}`)
            );
        case EEventName.PaymentStatusChanged:
            return (
                notification.payload?.id &&
                navigate(`${routes.transactions}/${notification.payload?.id}`)
            );
        case EEventName.MoneyRequestCreated:
            return (
                notification.payload?.id &&
                navigate(`${routes.transactions}/${notification.payload?.id}`)
            );
        case EEventName.CardCreated:
            return (
                notification.payload?.id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.id}`)
            );
        case EEventName.CardPinChanged:
            return (
                notification.payload?.id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.id}`)
            );
        case EEventName.CardLimitsUpdated:
            return (
                notification.payload?.id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.id}`)
            );
        case EEventName.CardStatusChanged:
            return (
                notification.payload?.id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.id}`)
            );
        case EEventName.CardBalanceLoaded:
            return (
                notification.payload?.id &&
                navigate(`${routes.xeppt_cards}/${notification.payload?.id}`)
            );
        case EEventName.SchedulePaymentProcessed:
            return notification.payload?.id && navigate(`${routes.pay_bills_schedule}`);
    }
};

const executeNotificationIcon = (notificationCategory?: ENotificationCategory) => {
    switch (notificationCategory) {
        case 'card':
            return 'credit_card';
        case 'schedule':
            return 'schedule';
        case 'payments':
            return 'money';
        case 'marketing':
            return 'marketing';
        case 'personal':
            return 'person';
        default:
            return 'warning';
    }
};

interface INotificationsData {
    notification: TNotification;
    locale: (event: EEventName, payload: Record<string, string | number | boolean>) => string;
    titleLocale: (val: string) => string;
    dateLocale: (val: string) => string;
    navigate: NavigateFunction;
    handleGetVerificationResult: (codes: string[], status: EUserStatus) => void;
}

export const executeNotificationsData = ({
    notification,
    handleGetVerificationResult,
    locale,
    navigate,
    titleLocale,
    dateLocale
}: INotificationsData) => {
    const notificationCategory = getNotificationGroupByEvent(notification.event);
    return {
        title: titleLocale(notificationCategory || 'general'),
        description: `${locale(notification.event, notification.payload)} ${getNotificationString(notification)}`,
        action: () => getNotificationAction(notification, navigate, handleGetVerificationResult),
        date: prettifyDate(notification.createdAt, dateLocale),
        icon: executeNotificationIcon(notificationCategory) as TIconType
    };
};
