import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { apiUserService } from '@api';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import DragAndDrop from '@components/drag_and_drop';
import { fileToBase64 } from '@utils/index';
import Button from '@components/common/button';
import Webcam from 'react-webcam';
import { useUserContext } from '@hooks/context/useUserContext';
import { useApiMutation } from '@hooks/api/useApiMutation';

interface IProps {
    handleNextStep: () => void;
    onClose: () => void;
}

const KycDocuments: FC<IProps> = ({ handleNextStep, onClose }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'kyc.first'
    });
    const { requestErrorLocale, requestSuccessLocale, submitLocale } = useLocales();
    const [disabled, setDisabled] = useState(false);
    const [selfie, setSelfie] = useState('');
    const [document, setDocument] = useState('');
    const webcamRef = useRef<any>(null);
    const [isWebcam, setIsWebcam] = useState(false);
    const [activeDocument, setActiveDocument] = useState<'selfie' | 'doc'>('selfie');
    const { refetchUser, refetchAccount } = useUserContext();

    useEffect(() => {
        if (!selfie || !document) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [document, selfie]);

    const { handleRequest: onSubmit, isLoading } = useApiMutation({
        method: () =>
            apiUserService.verify({
                face: selfie,
                document
            }),
        onSuccess: () => {
            requestSuccessLocale('kyc_start');
            handleNextStep();
            refetchUser();
            refetchAccount();
            onClose();
        },
        onError: requestErrorLocale
    });

    const handleMakePhoto = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (activeDocument === 'doc') {
            setDocument(imageSrc);
        } else {
            setSelfie(imageSrc);
        }
        setIsWebcam(false);
    }, [webcamRef, activeDocument]);

    return (
        <>
            <Typography variant="h4">{t('title')}</Typography>
            {!isWebcam ? (
                <>
                    <div className={styles.content}>
                        <div className={styles.row}>
                            <DragAndDrop
                                label={t('selfie')}
                                // disabled
                                preview={selfie}
                                onChange={async (selfie: File) => {
                                    setSelfie(await fileToBase64(selfie));
                                }}
                                // onClick={() => {
                                //     setIsWebcam(true);
                                //     setActiveDocument('selfie');
                                // }}
                            />
                            <Button
                                size="small"
                                variant="primary"
                                onClick={() => {
                                    setIsWebcam(true);
                                    setActiveDocument('selfie');
                                }}
                                className={styles.photo_button}>
                                {submitLocale('make_photo')}
                            </Button>
                        </div>
                        <div className={styles.row}>
                            <DragAndDrop
                                label={t('document')}
                                preview={document}
                                onChange={async (doc: File) => {
                                    setDocument(await fileToBase64(doc));
                                }}
                            />
                            <Button
                                size="small"
                                variant="primary"
                                onClick={() => {
                                    setIsWebcam(true);
                                    setActiveDocument('doc');
                                }}
                                className={styles.photo_button}>
                                {submitLocale('make_photo')}
                            </Button>
                        </div>
                    </div>
                    <Button
                        disabled={disabled}
                        size="small"
                        variant="primary"
                        onClick={onSubmit}
                        isLoading={isLoading}>
                        {submitLocale('submit')}
                    </Button>
                </>
            ) : (
                <>
                    <Webcam
                        screenshotFormat="image/png"
                        style={{ borderRadius: 8 }}
                        width="100%"
                        ref={webcamRef}
                    />
                    <div className={styles.actions}>
                        <Button size="small" variant="primary" onClick={() => setIsWebcam(false)}>
                            {submitLocale('cancel')}
                        </Button>
                        <Button size="small" variant="primary" onClick={handleMakePhoto}>
                            {submitLocale('make_photo')}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default KycDocuments;
