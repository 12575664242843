import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import moment from 'moment/moment';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { currencies } from '@const/currency';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components/icons';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    TTransactionLite
} from '@xeppt/xeppt-sdk/types';
import { getTransactionAction, getTransactionStatus, prettifyAmount } from '@utils/index';
import { moneyOutTransactions } from '@const/transactions';
import { formatDateForMoment } from '@utils/date';
import Status from '@components/common/status';

interface IProps {
    transaction: TTransactionLite;
    onClick?: (id: string) => void;
}

const cx = classNames.bind(styles);
const TransactionItem: FC<IProps> = ({ transaction, onClick }) => {
    const { t, i18n } = useTranslation('components', {
        keyPrefix: 'transaction_tables.transaction_item'
    });

    const isMoneyOutTransaction = useMemo(
        () => moneyOutTransactions.includes(transaction.action),
        [transaction]
    );

    const amountStyles = useMemo(
        () =>
            cx([
                styles.amount,
                {
                    plus: !isMoneyOutTransaction,
                    isCancel:
                        transaction.status === ETransactionStatus.CANCELED ||
                        transaction.status === ETransactionStatus.DECLINED
                }
            ]),
        [transaction]
    );

    const iconStyles = useMemo(
        () =>
            cx([
                styles.icon,
                {
                    isWallet: !isMoneyOutTransaction
                }
            ]),
        [transaction]
    );

    const isTransactionRequest = useMemo(
        () =>
            transaction?.action === ETransactionAction.REQUEST_MONEY ||
            transaction?.action === ETransactionAction.INCOMING_REQUEST_MONEY,
        [transaction]
    );

    const isLoadBalance = useMemo(
        () =>
            transaction.method === ETransactionMethod.EFT &&
            transaction.action === ETransactionAction.REQUEST_MONEY,
        [transaction]
    );

    const getTransactionTitle = useMemo(() => {
        let secondText = '';
        if (transaction.action === ETransactionAction.RECEIVE_MONEY) {
            secondText = transaction.source;
        } else if (transaction.action === ETransactionAction.REQUEST_MONEY) {
            secondText = transaction.source;
        } else if (transaction.action === ETransactionAction.INCOMING_REQUEST_MONEY) {
            secondText = transaction.source;
        } else if (transaction.action === ETransactionAction.SEND_MONEY) {
            secondText = transaction.destination;
        } else if (transaction.action === ETransactionAction.LOAD_CARD_BALANCE) {
            secondText = 'wallet';
        }
        return (
            (isLoadBalance
                ? t('load_balance')
                : isTransactionRequest
                  ? t('request_money')
                  : getTransactionAction(transaction.action, transaction.method, t)) +
            (secondText &&
                ` ${moneyOutTransactions.includes(transaction?.action) && transaction.action !== ETransactionAction.LOAD_CARD_BALANCE ? t('to') : t('from')} ${secondText}`)
        );
    }, [transaction]);

    return (
        <button
            className={styles.table_item}
            onClick={() => {
                if (onClick) onClick(transaction.id);
            }}>
            <div className={styles.left}>
                <div className={iconStyles}>
                    {transaction.automatic && (
                        <Icon
                            className={styles.recurring_icon}
                            name="schedule"
                            width={32}
                            height={32}
                        />
                    )}
                    {isMoneyOutTransaction ? (
                        !transaction.automatic && <Icon name="arrow_up" width={24} height={24} />
                    ) : (
                        <Icon name="arrow_down" width={24} height={24} />
                    )}
                </div>
                <div className={styles.text_wrapper}>
                    <Typography variant="body1" weight="semibold">
                        {getTransactionTitle}
                    </Typography>
                    <Typography variant="body3">
                        {moment(formatDateForMoment(transaction.createdAt)).format('MMM DD')},{' '}
                        {
                            enumTranslate[
                                transaction.action === ETransactionAction.SEND_MONEY &&
                                transaction.method === ETransactionMethod.EFT
                                    ? 'to_bank'
                                    : transaction.method
                            ][i18n.language as 'en' | 'fr']
                        }
                    </Typography>
                </div>
            </div>
            <div>
                <Typography variant="body1" weight="bold" className={amountStyles}>
                    {isMoneyOutTransaction && '-'}
                    {currencies[transaction.currency as 'CAD'].symbol}
                    {prettifyAmount(transaction.amount.toFixed(2))}
                </Typography>
                {transaction.status !== ETransactionStatus.APPROVED && (
                    <Status
                        variant={getTransactionStatus(transaction?.status)}
                        className={styles.status}>
                        {transaction?.status &&
                            ETransactionStatusTranslate[transaction.status][
                                i18n.language as 'en' | 'fr'
                            ]}
                    </Status>
                )}
            </div>
        </button>
    );
};

export default TransactionItem;
