import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Radio from '@components/common/radio';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

interface IProps {
    onTypeChange: (val: 'pay' | 'redeem') => void;
    type: 'pay' | 'redeem';
}

const cx = classNames.bind(styles);

const MainSection: FC<IProps> = ({ type, onTypeChange }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'cashback'
    });
    return (
        <>
            {/*<div className={styles.note}>*/}
            {/*    <span>{t('note')}</span>: {t('note_1')}*/}
            {/*</div>*/}
            <div className={styles.amount_wrapper}>
                <div className={styles.amount_title}>{t('amount')}</div>
                <div className={styles.amount}>$110.00</div>
            </div>
            <div className={styles.types_wrapper}>
                <div
                    className={cx([styles.type, { isActive: type === 'pay' }])}
                    onClick={() => onTypeChange('pay')}>
                    <Typography className={styles.title}>{t('pay_title')}</Typography>
                    <Typography>{t('pay_description')} </Typography>
                    <Radio checked={type === 'pay'} />
                </div>
                <div
                    className={cx([styles.type, { isActive: type === 'redeem' }])}
                    onClick={() => onTypeChange('redeem')}>
                    <Typography className={styles.title}>{t('redeem_title')}</Typography>
                    <Typography>{t('redeem_description')} </Typography>
                    <Radio checked={type === 'redeem'} />
                </div>
            </div>
        </>
    );
};

export default MainSection;
