export const getYears = (count: number, future: boolean) => {
    const finishYear = new Date().getFullYear() + (future ? 50 : 0),
        years = [];
    let startYear = finishYear - (count + (future ? 50 : 0));
    while (startYear <= finishYear) {
        years.push(startYear++);
    }
    return years;
};

export const prettifyDate = (date: Date, t: (val: string) => string) => {
    const now = new Date();
    const diff = Math.abs(now.getTime() - date.getTime()); // Difference in milliseconds

    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (minutes < 1) {
        return 'just now';
    } else if (minutes < 60) {
        return minutes === 1
            ? `1 ${t('minute')} ${t('ago')}`
            : `${minutes} ${t('minutes')} ${t('ago')}`;
    } else if (hours < 24) {
        return hours === 1 ? `1 ${t('hour')} ${t('ago')}` : `${hours} ${t('hours')} ${t('ago')}`;
    } else if (days <= 3) {
        return days === 1 ? `1 ${t('day')} ${t('ago')}` : `${days} ${t('days')} ${t('ago')}`;
    } else {
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long' };
        return date.toLocaleDateString(undefined, options);
    }
};

export const formatDateForMoment = (date: Date | string) => {
    const dateObj = new Date(date);

    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};
