import React from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext } from 'react-hook-form';
import FormField from '@components/form_field';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const BusinessDetailsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.business_details'
    });
    const { labelLocale, submitLocale } = useLocales();
    const { nextStep, prevStep } = useSignupContext();
    const { trigger } = useFormContext();

    const onSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                nextStep();
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <div className={styles.name_wrapper}>
                    <FormField
                        name="business.companyName"
                        renderComponent={(props) => (
                            <Input {...props} full required label={labelLocale('company_name')} />
                        )}
                    />
                    <FormField
                        name="business.tradingName"
                        renderComponent={(props) => (
                            <Input {...props} full required label={labelLocale('trading_name')} />
                        )}
                    />
                </div>
                <div className={styles.name_wrapper}>
                    <FormField
                        name="business.registrationNumber"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                required
                                label={labelLocale('registration_number')}
                            />
                        )}
                    />
                    <FormField
                        name="business.category"
                        renderComponent={(props) => (
                            <Input {...props} full required label={labelLocale('category')} />
                        )}
                    />
                </div>
                <div className={styles.name_wrapper}>
                    <FormField
                        name="business.size"
                        renderComponent={(props) => (
                            <Input {...props} full required label={labelLocale('size')} />
                        )}
                    />
                    <FormField
                        name="business.website"
                        renderComponent={(props) => (
                            <Input {...props} full required label={labelLocale('website')} />
                        )}
                    />
                </div>
                <div className={styles.actions}>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        size="normal"
                        variant="outlined-dark"
                        onClick={prevStep}>
                        {submitLocale('back')}
                    </Button>
                    <Button
                        className={styles.next}
                        onClick={onSubmit}
                        size="normal"
                        variant="primary">
                        {submitLocale('next')}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default BusinessDetailsSection;
