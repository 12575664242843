import React, { FC, useState } from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import RangeDatePicker from '@components/range_date_picker';
import TransactionTable from '@components/transactions_table';
import { getDateMonthAgo } from '@utils/index';

interface IProps {
    cardId?: string;
}

const TransactionsSection: FC<IProps> = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'xeppt_card.transaction'
    });

    const { dateNow, dateMonthAgo } = getDateMonthAgo();

    const [filter, setFilter] = useState({
        dateFrom: dateMonthAgo,
        dateTo: dateNow
    });
    // const { data: transactions, isLoading } = useApiQuery({
    //     method: () =>
    //         apiWalletService.getCardTransactions({
    //             startDate: filter.dateFrom,
    //             endDate: filter.dateTo,
    //             cardId: cardId || ''
    //         }),
    //     condition: !!cardId,
    //     deps: [filter, cardId]
    // });

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Typography variant="h4">{t('title')}</Typography>
                <div className={styles.range_date_wrapper}>
                    <RangeDatePicker
                        values={[filter.dateFrom?.toString(), filter.dateTo?.toString()]}
                        onChange={(val) => {
                            setFilter({
                                dateFrom: new Date(val?.[0] as string),
                                dateTo: new Date(val?.[1] as string)
                            });
                        }}
                        full
                    />
                </div>
            </div>
            <div className={styles.content}>
                <TransactionTable transactions={[]} isCard />
            </div>
        </div>
    );
};

export default TransactionsSection;
