import React from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useXepptCardModals } from '@hooks/modals/useXepptCardModals';

const ManageCardModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'manage_card'
    });
    const {
        handleOpenUpdatePinModal,
        handleOpenLimitsModal,
        handleOpenReplaceCardModal,
        handleOpenCancelCardModal
    } = useXepptCardModals({});

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" weight="semibold">
                {t('title')}
            </Typography>
            <div className={styles.content}>
                <button disabled className={styles.link} onClick={handleOpenUpdatePinModal}>
                    <div className={styles.text_wrapper}>
                        <span>
                            <Icon name="settings" />
                        </span>
                        <Typography variant="body1">{t('update_pin')} (disabled)</Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
                <button className={styles.link} onClick={handleOpenLimitsModal}>
                    <div className={styles.text_wrapper}>
                        <span>
                            <Icon name="limit" />
                        </span>
                        <Typography variant="body1">{t('view_limits')}</Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
                <button className={styles.link} onClick={handleOpenReplaceCardModal}>
                    <div className={styles.text_wrapper}>
                        <span>
                            <Icon name="sync" />
                        </span>
                        <Typography variant="body1">{t('replace_card')}</Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
                <button className={styles.link} onClick={handleOpenCancelCardModal}>
                    <div className={styles.text_wrapper}>
                        <span>
                            <Icon name="delete" />
                        </span>
                        <Typography variant="body1">{t('cancel_card')}</Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
            </div>
        </div>
    );
};

export default ManageCardModal;
