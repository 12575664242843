import { EUserStatus } from '@xeppt/xeppt-sdk/types/user';
import { useEffect } from 'react';
import { useUserContext } from '@hooks/context/useUserContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useKycModals } from '@hooks/modals/useKycModals';

interface IProps {
    onSuccess?: () => void;
}

export const useVerificationResult = (data?: IProps) => {
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { user, refetchUser, refetchAccount } = useUserContext();
    const { handleOpenErrorModal, handleOpenSuccessModal, handleOpenKycFirstModal } =
        useKycModals();

    const handleGetVerificationResult = (codes: string[], status: EUserStatus) => {
        if (status === EUserStatus.UNVERIFIED) {
            if (codes) {
                handleOpenErrorModal(codes);
            } else {
                handleOpenSuccessModal();
            }
            requestErrorLocale('failed_verify');
            refetchUser();
            refetchAccount();
        } else if (status === EUserStatus.ACTIVE) {
            data?.onSuccess && data?.onSuccess();
            requestSuccessLocale('kyc_success');
            refetchUser();
            refetchAccount();
        }
    };

    useEffect(() => {
        // if (user && user?.status === EUserStatus.PENDING) {
        //     getVerificationResult();
        // }
        if (user && user.status === EUserStatus.UNVERIFIED) {
            handleOpenKycFirstModal();
        }
    }, [user]);

    return {
        handleGetVerificationResult
    };
};
