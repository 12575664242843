import React from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext, useWatch } from 'react-hook-form';
import DatePicker from '@components/date_picker';
import FormField from '@components/form_field';
import { capitalizeFirstLetter, getBirthDisabledDates } from '@utils/index';
import { EUserType } from '@xeppt/xeppt-sdk/types';
import Select from '@components/common/select';
import { countriesList, occupationsList } from '@_mock/index';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const PersonalDetailsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.personal_details'
    });
    const { submitLocale, labelLocale } = useLocales();
    const { nextStep, prevStep } = useSignupContext();
    const { control, trigger } = useFormContext();
    const type = useWatch({ name: 'type', control });

    const onSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                nextStep();
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <div className={styles.name_wrapper}>
                    <FormField
                        name="profile.firstName"
                        renderComponent={(props) => (
                            <Input {...props} full required label={labelLocale('first_name')} />
                        )}
                    />
                    <FormField
                        name="profile.lastName"
                        renderComponent={(props) => (
                            <Input {...props} full required label={labelLocale('last_name')} />
                        )}
                    />
                </div>
                <FormField
                    name="profile.birthday"
                    renderComponent={(props) => (
                        <DatePicker
                            {...props}
                            full
                            maxDate={getBirthDisabledDates()}
                            label={labelLocale('birth_date')}
                        />
                    )}
                />
                <div className={styles.name_wrapper}>
                    {type === EUserType.BUSINESS && (
                        <FormField<string | number>
                            name="profile.residence"
                            renderComponent={(props) => (
                                <Select
                                    {...props}
                                    full
                                    isAllowSearch
                                    label={labelLocale('residence')}
                                    items={countriesList.map((item) => ({
                                        value: item,
                                        label: item
                                    }))}
                                />
                            )}
                        />
                    )}
                    <FormField<string | number>
                        name="profile.occupancy"
                        renderComponent={(props) => (
                            <Select
                                {...props}
                                full
                                isAllowSearch
                                label={labelLocale('occupation')}
                                items={occupationsList.map((item) => ({
                                    value: capitalizeFirstLetter(item),
                                    label: capitalizeFirstLetter(item)
                                }))}
                            />
                        )}
                    />
                </div>
                <div className={styles.actions}>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        onClick={prevStep}
                        variant="outlined-dark"
                        size="normal">
                        {submitLocale('back')}
                    </Button>
                    <Button
                        className={styles.next}
                        onClick={onSubmit}
                        size="normal"
                        variant="primary">
                        {submitLocale('next')}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default PersonalDetailsSection;
