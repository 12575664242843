import React, { useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import AccountsSection from '@sections/manage_accounts/accounts';
import Typography from '@components/common/typography';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useUserContext } from '@hooks/context/useUserContext';
import BankSection from '@sections/manage_accounts/bank';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import { useManageAccountModals } from '@hooks/modals/useManageAccountModals';

const ManageAccountsLayout = () => {
    const { t } = useTranslation('manage_accounts');
    const [id] = useQueryParams(['id']);
    const navigate = useNavigate();
    const { account } = useUserContext();

    const chosenAccount = useMemo(() => {
        return account?.bankAccounts?.find((item) => item.id === id) || account?.bankAccounts?.[0];
    }, [id, account]);

    const { removeBankAccount, setPreferredAccount } = useManageAccountModals({
        id,
        chosenAccount
    });

    useEffect(() => {
        if (chosenAccount) {
            navigate(`${routes.manage_accounts}?id=${chosenAccount.id}`);
        }
    }, [chosenAccount]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.content}>
                    <div className={styles.actions_wrapper}>
                        <div
                            className={styles.action}
                            onClick={() => navigate(routes.link_card_bank)}>
                            <Button iconSize={40} variant="icon" size="large" leftIcon="bank" />
                            {t('link_bank')}
                        </div>
                        {/*<div className={styles.action}>*/}
                        {/*    <Button iconSize={40} variant="icon" size="large" leftIcon="credit_card" />*/}
                        {/*    {t('link_card')}*/}
                        {/*</div>*/}
                    </div>
                    <AccountsSection />
                </div>
            </div>
            <div className={styles.right}>
                {chosenAccount && (
                    <>
                        {chosenAccount && <BankSection account={chosenAccount} />}
                        <div className={styles.actions}>
                            <div className={styles.action}>
                                <Button
                                    variant="icon-dark"
                                    leftIcon="favorite"
                                    onClick={setPreferredAccount}
                                />
                                <Typography>Set as favorite</Typography>
                            </div>
                            <div className={styles.action} onClick={removeBankAccount}>
                                <Button variant="icon" leftIcon="trash" />
                                <Typography>Remove</Typography>
                            </div>
                            {/*<div className={styles.action}>*/}
                            {/*    <Button variant="icon" leftIcon="refresh" />*/}
                            {/*    <Typography>Update</Typography>*/}
                            {/*</div>*/}
                        </div>
                    </>
                )}
            </div>
        </motion.div>
    );
};

export default ManageAccountsLayout;
