import { modalIds } from '@const/modals';
import useModalContext from '@hooks/context/useModalContext';

export const useKycModals = () => {
    const { onOpen, changeModalData } = useModalContext();

    const handleOpenPendingModal = () => {
        onOpen({
            modalId: modalIds.VERIFICATION_PENDING
        });
    };

    const handleOpenKycFirstModal = () => {
        onOpen({
            modalId: modalIds.KYC_FIRST_MODAL
        });
    };

    const handleOpenErrorModal = (codes: string[]) => {
        changeModalData({ isError: true, codes });
        onOpen({
            modalId: modalIds.KYC_FIRST_MODAL
        });
    };

    const handleOpenSuccessModal = () => {
        changeModalData({ isSuccess: true });
        onOpen({
            modalId: modalIds.KYC_FIRST_MODAL
        });
    };

    return {
        handleOpenPendingModal,
        handleOpenKycFirstModal,
        handleOpenErrorModal,
        handleOpenSuccessModal
    };
};
