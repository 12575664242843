import React, { FC, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import HeadItem from '@components/table/head_item';
import Checkbox from '@components/common/checkbox';
import Typography from '@components/common/typography';

export type TColumn = {
    label: string;
    key?: string;
    width?: number;
    isSort?: boolean;
    render?: (val: any, row: any, index: number) => ReactNode | string;
    isRightAlignment?: boolean;
    filterOptions?: {
        value: string;
        label: string;
    }[];
    isSingleFiler?: boolean;
};

export type TRow = Record<string, any>;

export type TFilter = {
    [key: string]: string[];
};

export type TSort = {
    key: string;
    value: 'asc' | 'desc';
};

interface IProps<T = any> {
    columns: TColumn[];
    rows?: TRow[];
    onRowClick?: (data: T) => void;
    onSortChange?: (key: string, value: 'asc' | 'desc') => void;
    onFilterChange?: (key: TFilter) => void;
    sort?: TSort;
    filter?: TFilter;
    isActions?: boolean;
    emptyDescription?: string;
    selectKey?: string;
    selected?: string[];
    onSelectedChange?: (val: string[]) => void;
    isSingleSelect?: boolean;
}

const cx = classNames.bind(styles);

const Table: FC<IProps> = ({
    columns,
    rows,
    onRowClick,
    onSortChange,
    sort,
    filter,
    onFilterChange,
    selectKey,
    selected,
    onSelectedChange,
    isSingleSelect,
    emptyDescription,
    isActions = false
}) => {
    const rowStyle = useMemo(() => cx([{ isOnclick: !!onRowClick }]), [onRowClick]);

    function getValueByPath(obj: any, path?: string) {
        if (path) {
            return path.split('.').reduce((acc, part) => acc && acc[part], obj);
        }
        return '';
    }

    const handleSortChange = (key?: string) => {
        if (onSortChange && key) {
            if (sort?.key === key) {
                if (sort?.value === 'asc') {
                    onSortChange(key, 'desc');
                } else {
                    onSortChange(key, 'asc');
                }
            } else {
                onSortChange(key, 'asc');
            }
        }
    };

    const handleFilterChange = (key: string, value: string, column: TColumn) => {
        if (onFilterChange) {
            if (filter?.[key] && !column?.isSingleFiler) {
                if (filter[key]?.includes(value)) {
                    onFilterChange({
                        ...filter,
                        [key]: filter[key]?.filter((item) => item !== value)
                    });
                } else {
                    onFilterChange({
                        ...filter,
                        [key]: [...filter[key], value]
                    });
                }
            } else {
                onFilterChange({
                    ...filter,
                    [key]: [value]
                });
            }
        }
    };

    const handleChangeSelect = (value: string) => {
        if (onSelectedChange && selected && selectKey) {
            if (selected?.includes(value)) {
                onSelectedChange(selected?.filter((item) => item !== value));
            } else {
                if (isSingleSelect) {
                    onSelectedChange([value]);
                } else {
                    onSelectedChange([...selected, value]);
                }
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={cx([styles.header, { isActions, isSelect: !!selectKey }])}>
                    <tr>
                        {selectKey && (
                            <th>
                                <div>Select</div>
                            </th>
                        )}
                        {columns.map((column, i) => {
                            return (
                                <HeadItem
                                    key={i}
                                    handleFilterChange={handleFilterChange}
                                    column={column}
                                    filter={filter}
                                    handleSortChange={handleSortChange}
                                    sort={sort}
                                    isActions={isActions && columns.length - 1 === i}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <tbody className={styles.body}>
                    {rows?.map((row, index) => {
                        return (
                            <tr key={index} className={rowStyle}>
                                {selectKey && (
                                    <td key={index}>
                                        <div className={styles.select_wrapper}>
                                            <Checkbox
                                                checked={selected?.includes(row?.[selectKey])}
                                                onClick={() =>
                                                    row?.[selectKey] &&
                                                    handleChangeSelect(row?.[selectKey])
                                                }
                                            />
                                        </div>
                                    </td>
                                )}
                                {columns.map((column, i) => {
                                    const columnStyle = {
                                        textAlign: column.isRightAlignment
                                            ? 'right'
                                            : column?.key && row?.[column?.key]
                                              ? 'left'
                                              : 'center',
                                        width: column.width
                                    };
                                    return (
                                        <td key={i}>
                                            <div
                                                //@ts-ignore
                                                style={columnStyle}
                                                onClick={() =>
                                                    onRowClick &&
                                                    i !== columns.length - 1 &&
                                                    isActions &&
                                                    onRowClick(row)
                                                }>
                                                {column?.render
                                                    ? column.render(
                                                          getValueByPath(row, column?.key),
                                                          row,
                                                          index
                                                      )
                                                    : getValueByPath(row, column?.key) || '-'}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {(rows || []).length === 0 && emptyDescription && (
                <div className={styles.empty_wrapper}>
                    <Typography variant="body3">{emptyDescription}</Typography>
                </div>
            )}
        </div>
    );
};

export default Table;
