import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { useFormContext } from 'react-hook-form';
import { getFrequencyList } from '@utils/billing';
import moment from 'moment/moment';
import { TPayee } from '@xeppt/xeppt-sdk/types/billing';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

interface IProps {
    onSubmit: () => void;
    onPrevStep: () => void;
    isLoading: boolean;
    chosenPayee?: TPayee;
}

const Verification: FC<IProps> = ({ onSubmit, onPrevStep, chosenPayee, isLoading }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.pay_bills.verification'
    });
    const { t: frequencyLocale } = useTranslation('sections', {
        keyPrefix: 'pay_bills.pay_bills.payment_details'
    });
    const { submitLocale } = useLocales();
    const { getValues } = useFormContext();

    const values = getValues();

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.ddr_info}>{t('title')}</div>
                <div className={`${styles.fees_container} ${styles.isOpen}`}>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('summary')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('summary_1')}</Typography>
                            <Typography>{chosenPayee?.alias || chosenPayee?.name}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('summary_2')}</Typography>
                            <Typography>${Number(values?.amount || 0).toFixed(2)} CAD</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('summary_3')}</Typography>
                            <Typography>
                                {getFrequencyList(frequencyLocale).find(
                                    (item) => item.value === values?.paymentFrequency
                                )?.label || ''}
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('summary_4')}</Typography>
                            <Typography>
                                {moment(values?.nextPaymentAt).format('MM/DD/YYYY')}
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('summary_5')}</Typography>
                            <Typography>
                                {moment(values?.paymentsEndDate).format('MM/DD/YYYY')}
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('summary_6')}</Typography>
                            <Typography>{values?.numberOfPayments}</Typography>
                        </div>
                        {/*<div className={styles.row}>*/}
                        {/*    <Typography>{t('summary_1')}</Typography>*/}
                        {/*    <Typography>$1.00</Typography>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={styles.ddr_info}>
                    <span>{t('note')}</span> {t('note_1')}
                </div>
            </div>
            <div className={styles.actions}>
                <Button
                    variant="outlined-dark"
                    size="normal"
                    leftIcon="arrow_left"
                    onClick={onPrevStep}>
                    {submitLocale('back')}
                </Button>
                <Button variant="primary" size="normal" onClick={onSubmit} isLoading={isLoading}>
                    {submitLocale('submit')}
                </Button>
            </div>
        </motion.div>
    );
};

export default Verification;
