import React, { useEffect } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useUserContext } from '@hooks/context/useUserContext';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import PersonalSection from '@sections/settings/personal';
import AddressSection from '@sections/settings/address';
import { zodResolver } from '@hookform/resolvers/zod';
import { profileSettingsValidation } from '@helpers/validation_objects/profile_settings';
import { EAddressType, TIndividualAddress, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiUserService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import AvatarSection from '@sections/settings/avatar';

const ProfileLayout = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'settings.personal'
    });
    const { user } = useUserContext();
    const { validationLocale, labelLocale } = useLocales();
    const formMethods = useForm<TIndividualProfile>({
        resolver: zodResolver(
            profileSettingsValidation({
                messages: {
                    firstName: validationLocale('firstName'),
                    lastName: validationLocale('lastName'),
                    birthDate: validationLocale('birthDate'),
                    occupancy: validationLocale('occupancy')
                }
            })
        )
    });

    useEffect(() => {
        if (user) {
            formMethods.reset(user);
        }
    }, [user]);

    const { data } = useApiQuery({
        method: () => apiUserService.getAddress()
    });

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <FormProvider {...formMethods}>
                <div className={styles.content}>
                    <AvatarSection type="user" />
                    <PersonalSection />
                    <AddressSection
                        label={labelLocale('billing_address')}
                        name="billingAddress"
                        type={EAddressType.BILLING}
                        data={data as TIndividualAddress}
                    />
                </div>
            </FormProvider>
        </motion.div>
    );
};

export default ProfileLayout;
