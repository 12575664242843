import { ETransactionAction } from '@xeppt/xeppt-sdk/types/transaction';

export const moneyOutTransactions = [
    ETransactionAction.SEND_MONEY,
    ETransactionAction.BILL_PAYMENT,
    ETransactionAction.REQUEST_CARD,
    ETransactionAction.INCOMING_REQUEST_MONEY,
    ETransactionAction.TRANSACTION_FEE,
    ETransactionAction.LOAD_CARD_BALANCE
];

export const moneyInTransactions = [
    ETransactionAction.REQUEST_MONEY,
    ETransactionAction.RECEIVE_MONEY
];

export const transactionsActionsFilterArray = (t: (val: string) => string) => [
    {
        label: t('transaction_fee'),
        action: ETransactionAction.TRANSACTION_FEE
    },
    {
        label: t('send'),
        action: ETransactionAction.SEND_MONEY
    },
    {
        label: t('bill'),
        action: ETransactionAction.BILL_PAYMENT
    },
    {
        label: t('incoming_request'),
        action: ETransactionAction.INCOMING_REQUEST_MONEY
    },
    {
        label: t('request_card'),
        action: ETransactionAction.REQUEST_CARD
    },
    {
        label: t('received'),
        action: ETransactionAction.RECEIVE_MONEY
    },
    {
        label: t('request_money'),
        action: ETransactionAction.REQUEST_MONEY
    },
    {
        label: t('load_card'),
        action: ETransactionAction.LOAD_CARD_BALANCE
    }
];
