import React, { FC, SVGAttributes } from 'react';

const Block: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0003 2.1665C5.40033 2.1665 1.66699 5.89984 1.66699 10.4998C1.66699 15.0998 5.40033 18.8332 10.0003 18.8332C14.6003 18.8332 18.3337 15.0998 18.3337 10.4998C18.3337 5.89984 14.6003 2.1665 10.0003 2.1665ZM3.33366 10.4998C3.33366 6.8165 6.31699 3.83317 10.0003 3.83317C11.542 3.83317 12.9587 4.35817 14.0837 5.2415L4.74199 14.5832C3.85866 13.4582 3.33366 12.0415 3.33366 10.4998ZM10.0003 17.1665C8.45866 17.1665 7.04199 16.6415 5.91699 15.7582L15.2587 6.4165C16.142 7.5415 16.667 8.95817 16.667 10.4998C16.667 14.1832 13.6837 17.1665 10.0003 17.1665Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Block;
