import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import useCollapse from '@hooks/helpers/useCollapse';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import FormField from '@components/form_field';
import { useUserContext } from '@hooks/context/useUserContext';
import { apiContactService } from '@api';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import { EPaymentFrequency } from '@xeppt/xeppt-sdk/types/billing';
import Select from '@components/common/select';
import {
    convertContactsListToOptions,
    excludeExistedContacts,
    getDisablePast,
    searchByContacts
} from '@utils/index';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import Skeleton from '@components/common/skeleton';
import { Icon } from '@components/icons';
import Textarea from '@components/common/textarea';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { EDdrStatus, TContact } from '@xeppt/xeppt-sdk/types';
import SearchSelect from '@components/common/search_select';
import { useDebounceValue } from 'usehooks-ts';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useFrequency } from '@hooks/helpers/useFrequency';
import { useInteracModals } from '@hooks/modals/useInteracModals';
import { getFrequencyList } from '@utils/billing';
import DatePicker from '@components/date_picker';
import Radio from '@components/common/radio';

interface IProps {
    onNextStep: () => void;
    onPrevStep: () => void;
    refetchContacts: () => void;
    method: ESendRequestMoneyMethod;
    contactsList: TContact[];
}

const Info: FC<IProps> = ({ onNextStep, method, onPrevStep, contactsList, refetchContacts }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money.info'
    });
    const { t: recurringLocale } = useTranslation('sections', {
        keyPrefix: 'send_and_request.recurring'
    });
    const navigate = useNavigate();
    const { isOpened, onToggle, contentStyles, contentRef } = useCollapse(true);
    const { account, user } = useUserContext();
    const feeAnchorRef = useRef(null);
    const { handleChangeInteracEmail } = useInteracModals();
    const { requestErrorLocale, labelLocale, submitLocale, validationLocale } = useLocales();
    const form = useFormContext();
    const watchedContact = useWatch({ name: 'contact', control: form.control });
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);
    const [search, setSearch] = useState('');
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control: form.control });
    const isFrequencyExist = useMemo(
        () => watchedFrequency !== EPaymentFrequency.ONCE,
        [watchedFrequency]
    );
    const { amountSendMoneyValidation } = useFrequency({ isFrequencyExist, method });
    const isBankSend = useMemo(() => method === ESendRequestMoneyMethod.BANK, [method]);
    const isWalletSend = useMemo(() => method === ESendRequestMoneyMethod.INTERNAL, [method]);
    const isETransferSend = useMemo(() => method === ESendRequestMoneyMethod.E_TRANSFER, [method]);

    const { data: searchContacts } = useApiQuery({
        method: () => apiContactService.searchContact(requestSearch),
        deps: [requestSearch],
        condition: requestSearch.length >= 3
    });

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    const { data: ddrContactStatus, isLoading: isContactDdrLoading } = useApiQuery({
        method: () => apiContactService.checkContactDDR(watchedContact),
        deps: [watchedContact],
        condition: isETransferSend && watchedContact
    });

    const { handleRequest: handleSaveContact } = useApiMutation({
        method: () => {
            const values = form.getValues();
            return apiContactService.addContact(values.contact);
        },
        onSuccess: (data) => {
            form.setValue('contact', data.id);
            refetchContacts();
            onNextStep();
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        const values = form.getValues();
        form.trigger().then((isValid) => {
            const foundUserSearch = searchContacts?.find((item) => item.id === values.contact);
            const foundUserContact =
                contactsList.find((item) => item.tag === foundUserSearch?.tag) ||
                contactsList.find((item) => item.id === values?.contact);
            if (isValid) {
                if (isWalletSend && !foundUserContact) {
                    handleSaveContact(undefined);
                } else {
                    form.setValue('contact', foundUserContact?.id);
                    onNextStep();
                }
            }
        });
    };

    const renderDescription = () => {
        switch (method) {
            case ESendRequestMoneyMethod.E_TRANSFER:
                return (
                    <>
                        {t('description')}{' '}
                        <a
                            onClick={() => {
                                !isOpened && onToggle();
                                //@ts-ignore
                                ref?.current?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start'
                                });
                            }}>
                            {t('description_fees')}
                        </a>
                        .
                    </>
                );
            case ESendRequestMoneyMethod.INTERNAL:
                return t('description_internal');
            case ESendRequestMoneyMethod.BANK:
                return t('description_bank');
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {renderDescription()}
                </Typography>
                {isETransferSend && (
                    <div className={styles.limits}>
                        <Typography variant="body3" weight="semibold">
                            {t('limits_title')}
                        </Typography>
                        <div>
                            <Typography variant="body3" weight="semibold">
                                {t('available')} $
                                {account?.eTransferAccount?.dailyLimit?.toFixed(2)}
                            </Typography>
                            <Typography variant="body3">{t('daily_limit')} $3,000.00</Typography>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{t('from')}</Typography>
                    <div className={styles.actions}>
                        <Typography variant="body3">
                            {user?.profile?.firstName} {user?.profile?.lastName} (
                            {account?.eTransferAccount?.interacEmail})
                        </Typography>
                        <Button leftIcon="edit" onClick={handleChangeInteracEmail} />
                    </div>
                </div>
                <Typography variant="body3" className={styles.balance}>
                    {t('current_balance')}: ${(account?.balance || 0)?.toFixed(2)}
                </Typography>
                <div className={styles.form_row}>
                    <FormField
                        name="amount"
                        renderComponent={(props) => (
                            <Input
                                full
                                label={labelLocale('amount')}
                                prefix="CAD"
                                rightIcon="canadian"
                                fixedAmount
                                onlyNumbers
                                hideErrorIcon
                                {...props}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('amount') },
                            validate: amountSendMoneyValidation
                        }}
                    />
                </div>
                {/*{isWalletSend && (*/}
                {/*    <>*/}
                {/*        <div className={styles.form_row}>*/}
                {/*            <FormField<string | number>*/}
                {/*                name="paymentFrequency"*/}
                {/*                renderComponent={(props) => (*/}
                {/*                    <Select*/}
                {/*                        full*/}
                {/*                        label={labelLocale('frequency')}*/}
                {/*                        {...props}*/}
                {/*                        items={getFrequencyList(recurringLocale)}*/}
                {/*                    />*/}
                {/*                )}*/}
                {/*                rules={{*/}
                {/*                    required: {*/}
                {/*                        value: true,*/}
                {/*                        message: validationLocale('frequency')*/}
                {/*                    }*/}
                {/*                }}*/}
                {/*            />*/}
                {/*            <FormField*/}
                {/*                name="nextPaymentAt"*/}
                {/*                renderComponent={(props) => (*/}
                {/*                    <DatePicker*/}
                {/*                        label={recurringLocale('next_payment')}*/}
                {/*                        minDate={getDisablePast()}*/}
                {/*                        full*/}
                {/*                        {...props}*/}
                {/*                    />*/}
                {/*                )}*/}
                {/*                rules={{*/}
                {/*                    required: {*/}
                {/*                        value: true,*/}
                {/*                        message: validationLocale('next_payment')*/}
                {/*                    }*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        {isFrequencyExist && (*/}
                {/*            <div className={styles.form_row}>*/}
                {/*                <div className={styles.full_row}>*/}
                {/*                    <FormField<ESchedulePaymentType>*/}
                {/*                        name="paymentType"*/}
                {/*                        renderComponent={({ value, onChange }) => (*/}
                {/*                            <>*/}
                {/*                                <div*/}
                {/*                                    className={styles.row}*/}
                {/*                                    onClick={() =>*/}
                {/*                                        onChange(ESchedulePaymentType.PAYMENTS_DATE)*/}
                {/*                                    }>*/}
                {/*                                    <Radio*/}
                {/*                                        small*/}
                {/*                                        checked={*/}
                {/*                                            value ===*/}
                {/*                                            ESchedulePaymentType.PAYMENTS_DATE*/}
                {/*                                        }*/}
                {/*                                    />{' '}*/}
                {/*                                    <Typography variant="body3">*/}
                {/*                                        {recurringLocale('ending_date')}*/}
                {/*                                    </Typography>*/}
                {/*                                </div>*/}
                {/*                                <FormField*/}
                {/*                                    name="paymentsEndDate"*/}
                {/*                                    renderComponent={(props) => (*/}
                {/*                                        <DatePicker*/}
                {/*                                            full*/}
                {/*                                            label={recurringLocale('ending_date')}*/}
                {/*                                            disabled={*/}
                {/*                                                value !==*/}
                {/*                                                ESchedulePaymentType.PAYMENTS_DATE*/}
                {/*                                            }*/}
                {/*                                            {...props}*/}
                {/*                                        />*/}
                {/*                                    )}*/}
                {/*                                />*/}
                {/*                            </>*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className={styles.full_row}>*/}
                {/*                    <FormField<ESchedulePaymentType>*/}
                {/*                        name="paymentType"*/}
                {/*                        renderComponent={({ value, onChange }) => (*/}
                {/*                            <>*/}
                {/*                                <div*/}
                {/*                                    className={styles.row}*/}
                {/*                                    onClick={() =>*/}
                {/*                                        onChange(*/}
                {/*                                            ESchedulePaymentType.NUMBER_OF_PAYMENTS*/}
                {/*                                        )*/}
                {/*                                    }>*/}
                {/*                                    <Radio*/}
                {/*                                        small*/}
                {/*                                        checked={*/}
                {/*                                            value ===*/}
                {/*                                            ESchedulePaymentType.NUMBER_OF_PAYMENTS*/}
                {/*                                        }*/}
                {/*                                    />{' '}*/}
                {/*                                    <Typography variant="body3">*/}
                {/*                                        <span>{recurringLocale('or')}</span>{' '}*/}
                {/*                                        {recurringLocale('end')}*/}
                {/*                                    </Typography>*/}
                {/*                                </div>*/}
                {/*                                <FormField*/}
                {/*                                    name="numberOfPayments"*/}
                {/*                                    renderComponent={(props) => (*/}
                {/*                                        <Input*/}
                {/*                                            disabled={*/}
                {/*                                                value !==*/}
                {/*                                                ESchedulePaymentType.NUMBER_OF_PAYMENTS*/}
                {/*                                            }*/}
                {/*                                            full*/}
                {/*                                            label={labelLocale(*/}
                {/*                                                'number_of_payments'*/}
                {/*                                            )}*/}
                {/*                                            onlyNumbers*/}
                {/*                                            {...props}*/}
                {/*                                        />*/}
                {/*                                    )}*/}
                {/*                                />*/}
                {/*                            </>*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    </>*/}
                {/*)}*/}
                {method !== ESendRequestMoneyMethod.BANK && (
                    <FormField
                        name="message"
                        renderComponent={(props) => (
                            <Textarea
                                full
                                label={labelLocale('message_optional')}
                                maxLength={250}
                                {...props}
                            />
                        )}
                    />
                )}
            </div>
            {method !== ESendRequestMoneyMethod.BANK && (
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('to')}</Typography>
                        <div className={styles.actions}>
                            <Button
                                variant="outlined-dark"
                                size="small"
                                onClick={() => navigate(routes.send_and_request_contacts)}>
                                {submitLocale('add_new')}
                            </Button>
                        </div>
                    </div>
                    {isETransferSend && (
                        <FormField<string | number>
                            name="contact"
                            renderComponent={(props) => (
                                <Select
                                    full
                                    label={labelLocale('contact')}
                                    items={
                                        contactsList?.map((item) => ({
                                            //@ts-ignore
                                            label: `${item.firstName} ${item.lastName} (${item.email || (item?.phone && `+${item?.phone}`)})`,
                                            value: item.id
                                        })) || []
                                    }
                                    {...props}
                                />
                            )}
                            rules={{
                                required: { value: true, message: validationLocale('contact') }
                            }}
                        />
                    )}
                    {isWalletSend && (
                        <FormField<string | number>
                            name="contact"
                            renderComponent={(props) => (
                                <SearchSelect
                                    full
                                    label={labelLocale('contact')}
                                    splitArrays={[
                                        {
                                            label: 'Your Contacts',
                                            array: convertContactsListToOptions(
                                                searchByContacts(search, contactsList)
                                            )
                                        },
                                        {
                                            label: 'XEPPT Contacts',
                                            array: convertContactsListToOptions(
                                                excludeExistedContacts(contactsList, searchContacts)
                                            )
                                        }
                                    ]}
                                    search={search}
                                    onChangeSearch={(val) => setSearch(val)}
                                    {...props}
                                />
                            )}
                            rules={{
                                required: { value: true, message: validationLocale('contact') }
                            }}
                        />
                    )}
                    {isETransferSend && (
                        <>
                            {isContactDdrLoading ? (
                                <>
                                    <Skeleton className={styles.skeleton_info} />
                                    <Skeleton className={styles.skeleton} />
                                    <Skeleton className={styles.skeleton} />
                                </>
                            ) : (
                                <>
                                    {ddrContactStatus === EDdrStatus.ENABLED && (
                                        <div className={styles.ddr_info}>
                                            <Icon name="info" />
                                            <span>{t('autodeposit_title')}</span> -{' '}
                                            {t('autodeposit_description')}
                                        </div>
                                    )}
                                    {ddrContactStatus === EDdrStatus.DISABLED && (
                                        <>
                                            <div className={styles.ddr_info}>
                                                <Icon name="info" />
                                                {t('autodeposit_off')}
                                            </div>
                                            <FormField
                                                name="securityQuestion"
                                                renderComponent={(props) => (
                                                    <Input
                                                        full
                                                        label={labelLocale('security_question')}
                                                        {...props}
                                                    />
                                                )}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: validationLocale(
                                                            'invalid_security_question'
                                                        )
                                                    },
                                                    validate: (value: string) => {
                                                        const cleanValue = value.replaceAll(
                                                            ' ',
                                                            ''
                                                        );
                                                        if (cleanValue.length >= 40) {
                                                            return validationLocale(
                                                                'invalid_security_question_length'
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                            <FormField
                                                name="securityAnswer"
                                                renderComponent={(props) => (
                                                    <Input
                                                        full
                                                        label={labelLocale('answer')}
                                                        {...props}
                                                    />
                                                )}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            validationLocale(
                                                                'invalid_security_answer'
                                                            )
                                                    },
                                                    validate: (value: string) => {
                                                        const cleanValue = value.replaceAll(
                                                            ' ',
                                                            ''
                                                        );
                                                        if (
                                                            cleanValue.length <= 3 ||
                                                            cleanValue.length >= 25
                                                        ) {
                                                            return validationLocale(
                                                                'invalid_security_answer_length'
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
            {isBankSend && (
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('to')}</Typography>
                        <div className={styles.actions}>
                            <Button
                                variant="outlined-dark"
                                size="small"
                                onClick={() => navigate(routes.link_card_bank)}>
                                {submitLocale('add_new')}
                            </Button>
                        </div>
                    </div>
                    <FormField<string | number>
                        name="account"
                        renderComponent={(props) => (
                            <Select
                                full
                                label={labelLocale('bank_account')}
                                items={
                                    account?.bankAccounts?.map((item) => ({
                                        label: `${item.institutionName} (${item.accountName} ${item.accountNumber})`,
                                        value: item.id
                                    })) || []
                                }
                                {...props}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('bank_account') }
                        }}
                    />
                </div>
            )}
            <div className={styles.actions}>
                <Button
                    size="normal"
                    onClick={onPrevStep}
                    leftIcon="arrow_left"
                    variant="outlined-dark">
                    {submitLocale('back')}
                </Button>
                <Button variant="primary" size="normal" onClick={onSubmit}>
                    {submitLocale('next')}
                </Button>
            </div>
            {isETransferSend && (
                <div className={styles.fees}>
                    <Button onClick={onToggle}>
                        <div className={`${styles.icon} ${isOpened ? styles.isOpen : ''}`} />{' '}
                        <Typography variant="h5">{t('fees')}</Typography>
                    </Button>
                    <div
                        className={`${styles.fees_container} ${isOpened ? styles.isOpen : ''}`}
                        style={contentStyles}
                        ref={contentRef}>
                        <div className={styles.fees_wrapper} ref={feeAnchorRef}>
                            <Typography variant="h5">{t('fee_personal')}</Typography>
                            <div className={styles.row}>
                                <Typography>{t('fee_personal_1')}</Typography>
                                <Typography>{t('fee_personal_2')}</Typography>
                            </div>
                            <div className={styles.row}>
                                <Typography>{t('fee_personal_3')}</Typography>
                                <Typography>$5 {t('fee_personal_4')}</Typography>
                            </div>
                        </div>
                        <div className={styles.fees_wrapper}>
                            <Typography variant="h5">{t('fee_business')}</Typography>
                            <div className={styles.row}>
                                <Typography>{t('fee_business_1')}</Typography>
                                <Typography>$1.50 {t('fee_business_2')}</Typography>
                            </div>
                            <div className={styles.row}>
                                <Typography>{t('fee_business_3')}</Typography>
                                <Typography>$5 {t('fee_business_4')}</Typography>
                            </div>
                        </div>
                    </div>
                    <div className={styles.notes}>
                        {t('notes')}
                        <div className={styles.row}>
                            <span>1.</span>
                            <div>{t('notes_1')}</div>
                        </div>
                        <div className={styles.row}>
                            <span>2.</span>
                            <div>{t('notes_2')}</div>
                        </div>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default Info;
