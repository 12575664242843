import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { IModalComponentProps } from '@context/modal_context';
import { formatDateForMoment } from '@utils/date';
import { hideCardNumber } from '@utils/index';

const CardDetailsModal: FC<
    IModalComponentProps<{ cardHolder: string; cardNumber: string; expiration: string }>
> = ({ modalData }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'card_details'
    });
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.content}>
                <div className={styles.row}>
                    <Typography variant="body3">{t('holder_name')}</Typography>
                    <span>{modalData?.cardHolder}</span>
                </div>
                <div className={styles.row}>
                    <Typography variant="body3">{t('card_number')}</Typography>
                    <span>{hideCardNumber(modalData?.cardNumber)}</span>
                </div>
                <div className={styles.row}>
                    <Typography variant="body3">{t('expiry_date')}</Typography>
                    <span>
                        {moment(formatDateForMoment(modalData?.expiration)).format('MM/YY')}
                    </span>
                </div>
                {/*<div className={styles.row}>*/}
                {/*    <Typography variant="body3">CVV</Typography>*/}
                {/*    <span>664</span>*/}
                {/*</div>*/}
                {/*<div className={styles.row}>*/}
                {/*    <Typography variant="body3">{t('billing_address')}</Typography>*/}
                {/*    <span>155 Edward Street, Aurora, ON L4R 9N0, Canada</span>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default CardDetailsModal;
