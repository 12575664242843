import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { ECardType, TCardLite } from '@xeppt/xeppt-sdk/types/card';
import FormField from '@components/form_field';
import { useUserContext } from '@hooks/context/useUserContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import classNames from 'classnames/bind';
import { ECardStatus } from '@xeppt/xeppt-sdk/types';

interface IProps {
    nextStep: () => void;
    cards: TCardLite[];
}

const physicalAmount = 7;

const cx = classNames.bind(styles);

const CardTypeSection: FC<IProps> = ({ nextStep, cards }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'order_card.card_type'
    });
    const { account } = useUserContext();
    const { submitLocale } = useLocales();
    const navigate = useNavigate();

    const isEnoughAmount = useMemo(() => {
        return (account?.balance || 0) < physicalAmount;
    }, [account, physicalAmount]);

    return (
        <div className={styles.wrapper}>
            {/*<FormField*/}
            {/*    name="type"*/}
            {/*    renderComponent={({ onChange }) => {*/}
            {/*        return (*/}
            {/*            <div className={styles.card}>*/}
            {/*                <Typography className={styles.title} variant="h4">*/}
            {/*                    {t('virtual_card')}*/}
            {/*                </Typography>*/}
            {/*                <Typography className={styles.description} variant="body3">*/}
            {/*                    {t('virtual_description')}*/}
            {/*                </Typography>*/}
            {/*                <div className={styles.shipping}>*/}
            {/*                    <Icon name="send" width={20} height={20} />*/}
            {/*                    {t('virtual_get')}*/}
            {/*                </div>*/}
            {/*                <div className={styles.tag}>{t('virtual_free')}</div>*/}
            {/*                <Button*/}
            {/*                    variant="primary"*/}
            {/*                    size="normal"*/}
            {/*                    disabled*/}
            {/*                    onClick={() => {*/}
            {/*                        onChange(ECardType.VIRTUAL as unknown as string)*/}
            {/*                        nextStep()*/}
            {/*                    }}>*/}
            {/*                    {t('virtual_now')}*/}
            {/*                </Button>*/}
            {/*            </div>*/}
            {/*        );*/}
            {/*    }}*/}
            {/*/>*/}
            <FormField
                name="type"
                renderComponent={({ onChange }) => {
                    return (
                        <div className={styles.card}>
                            <div className={styles.content_wrapper}>
                                <Typography className={styles.title} variant="h4">
                                    {t('physical_card')}
                                </Typography>
                                <Typography className={styles.description} variant="body3">
                                    {t('physical_description')}
                                </Typography>
                                <div className={styles.shipping}>
                                    <Icon name="send" width={20} height={20} />
                                    {t('physical_get')}
                                </div>
                                <div className={styles.tag}>${physicalAmount.toFixed(2)}</div>
                                {isEnoughAmount && (
                                    <Typography variant="body3" className={styles.helper_text}>
                                        {t('not_enough_balance')}
                                    </Typography>
                                )}
                            </div>
                            <Button
                                variant="primary"
                                size="normal"
                                onClick={() => {
                                    if (isEnoughAmount) {
                                        navigate(routes.send_and_request_request);
                                    } else {
                                        onChange(ECardType.PHYSICAL as unknown as string);
                                        nextStep();
                                    }
                                }}>
                                {isEnoughAmount ? submitLocale('load_balance') : t('physical_now')}
                            </Button>
                        </div>
                    );
                }}
            />
            <FormField
                name="type"
                renderComponent={({ onChange }) => {
                    return (
                        <div className={cx([styles.card])}>
                            <div className={styles.content_wrapper}>
                                <Typography className={styles.title} variant="h4">
                                    {t('supplementary_card')}
                                </Typography>
                                <Typography className={styles.description} variant="body3">
                                    {t('supplementary_description')}
                                </Typography>
                                <div className={styles.shipping}>
                                    <Icon name="send" width={20} height={20} />
                                    {t('supplementary_get')}
                                </div>
                                <div className={styles.tag}>Free</div>
                            </div>
                            <Button
                                variant="primary"
                                size="normal"
                                disabled={
                                    (cards || [])?.length === 0 &&
                                    !cards?.find(
                                        (item) =>
                                            item.type === ECardType.PHYSICAL &&
                                            item.status === ECardStatus.ACTIVE
                                    )
                                }
                                onClick={() => {
                                    onChange(ECardType.PHYSICAL_SUPPLEMENTARY as unknown as string);
                                    nextStep();
                                }}>
                                {t('supplementary_now')}
                            </Button>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default CardTypeSection;
