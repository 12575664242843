import React, { FC, useMemo } from 'react';
import styles from '@layouts/settings/profile/styles.module.scss';
import Typography from '@components/common/typography';
import { getFirstLetter } from '@utils/index';
import { Icon } from '@components/icons';
import { useSettingsModals } from '@hooks/modals/useSettingsModals';
import { useUserContext } from '@hooks/context/useUserContext';

interface IProps {
    type: 'user' | 'business';
}

const AvatarSection: FC<IProps> = ({ type }) => {
    const { user, businessData } = useUserContext();
    const { handleChangeAvatar, handleDeleteAvatar } = useSettingsModals();

    const renderAbbreviate = useMemo(() => {
        switch (type) {
            case 'user':
                return (
                    <>
                        {getFirstLetter(user?.profile?.firstName)}
                        {getFirstLetter(user?.profile?.lastName)}
                    </>
                );
            case 'business':
                return getFirstLetter(businessData?.companyName);
        }
    }, [type, user, businessData]);

    const userAvatar = useMemo(() => user?.profile?.avatar, [user]);
    return (
        <div className={styles.avatar_wrapper}>
            <div>
                {userAvatar ? (
                    <img src={userAvatar} alt="your avatar" />
                ) : (
                    <Typography variant="h3">{renderAbbreviate}</Typography>
                )}
                <div className={styles.actions_wrapper}>
                    <button onClick={handleChangeAvatar}>
                        <Icon name="edit" />
                    </button>
                    {userAvatar && (
                        <button onClick={handleDeleteAvatar}>
                            <Icon name="trash" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AvatarSection;
