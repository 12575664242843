import React, { FC, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import classNames from 'classnames/bind';
import useResponsive from '@hooks/helpers/useResponsive';

interface IProps {
    variant: 'top' | 'right' | 'left' | 'bottom';
    className?: string;
    content: ReactNode;
    children?: ReactNode;
    maxWidth?: boolean;
}

const cx = classNames.bind(styles);

const Info: FC<IProps> = ({ variant, content, className, children, maxWidth }) => {
    const { isMobile } = useResponsive();
    const wrapperStyle = useMemo(
        () =>
            cx([
                styles.wrapper,
                isMobile ? 'bottom' : variant,
                className,
                { isMaxWidth: maxWidth }
            ]),
        [variant, className, isMobile, maxWidth]
    );

    const contentStyle = useMemo(
        () => cx([styles.content, isMobile ? 'bottom' : variant, className]),
        [variant, className, isMobile]
    );

    return (
        <div className={wrapperStyle}>
            {children || <Icon name="error_filled" />}
            <div className={contentStyle}>{content}</div>
        </div>
    );
};

export default Info;
