import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import { TCardTransaction } from '@xeppt/xeppt-sdk/types';
import { prettifyAmount } from '@utils/index';
import { currencies } from '@const/currency';
import moment from 'moment/moment';
import { enumTranslate } from '@locales/index';
import { ETransactionMethod } from '@xeppt/xeppt-sdk/types/transaction';
import { useTranslation } from 'react-i18next';
import { formatDateForMoment } from '@utils/date';

interface IProps {
    transaction: TCardTransaction;
    onClick?: (id: string) => void;
}

const cx = classNames.bind(styles);
const CardTransactionItem: FC<IProps> = ({ transaction }) => {
    const { i18n } = useTranslation();
    const isPay = useMemo(() => {
        return transaction.transactionTypeCode === 'C';
    }, [transaction]);

    const transactionAmount = useMemo(() => {
        return !!transaction.settlementAmount
            ? transaction.settlementAmount
            : transaction.transactionAmount;
    }, [transaction]);

    const amountStyles = useMemo(
        () => cx([styles.amount, { plus: transaction.transactionTypeCode === 'D' }]),
        [transaction]
    );

    const iconStyles = useMemo(
        () =>
            cx([
                styles.icon,
                {
                    isWallet: !isPay
                }
            ]),
        [transaction]
    );

    const transactionDate = useMemo(() => {
        return !!transaction.settlementDate
            ? transaction.settlementDate
            : transaction.transactionDate;
    }, [transaction]);

    return (
        <button
            className={styles.table_item}
            onClick={() => {
                // if (onClick) onClick(transaction.id);
            }}>
            <div className={styles.left}>
                <div className={iconStyles}>
                    {isPay ? (
                        <Icon name="arrow_up" width={24} height={24} />
                    ) : (
                        <Icon name="arrow_down" width={24} height={24} />
                    )}
                </div>
                <div>
                    <Typography variant="body1" weight="semibold">
                        {transaction.transactionType}
                    </Typography>
                    <Typography variant="body3">
                        {moment(formatDateForMoment(transactionDate)).format('MMM DD')},{' '}
                        {enumTranslate[ETransactionMethod.CARD][i18n.language as 'en' | 'fr']}
                    </Typography>
                </div>
            </div>
            <Typography variant="body1" weight="bold" className={amountStyles}>
                {transaction.transactionTypeCode === 'C'
                    ? `-${currencies[transaction.transactionCurrencyCode as 'CAD'].symbol}${prettifyAmount(transactionAmount.toFixed(2))}`
                    : `${currencies[transaction.transactionCurrencyCode as 'CAD'].symbol}${prettifyAmount(transactionAmount.toFixed(2))}`}
            </Typography>
        </button>
    );
};

export default CardTransactionItem;
