import React, { FC } from 'react';
import styles from './styles.module.scss';
import illustration from '@svg/illustrations/success_money_add.svg';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';

interface IProps {
    type: 'pay' | 'redeem';
}

const SuccessSection: FC<IProps> = ({ type }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'cashback'
    });
    return (
        <div className={styles.success}>
            <img src={illustration} alt="money move success" />
            <div className={styles.title}>SUCCESS!</div>
            <Typography variant="body1">
                {t(type === 'pay' ? 'success_pay' : 'success_redeem')}
            </Typography>
        </div>
    );
};

export default SuccessSection;
