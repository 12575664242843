import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import FormField from '@components/form_field';
import { apiContactService } from '@api';
import { EContactType, TContact, TCreateContact } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import Radio from '@components/common/radio';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useDebounceValue } from 'usehooks-ts';
import SearchSelect from '@components/common/search_select';
import { convertContactsListToOptions } from '@utils/index';

interface IProps {
    handleBack: () => void;
    isEdit: boolean;
    editableContact?: TContact;
}

const Contact: FC<IProps> = ({ handleBack, editableContact }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.manage_contacts'
    });
    const form = useForm({});
    const {
        requestSuccessLocale,
        requestErrorLocale,
        labelLocale,
        validationLocale,
        submitLocale
    } = useLocales();
    const [isEmail, setIsEmail] = useState(true);
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);
    const [search, setSearch] = useState('');
    const [type, setType] = useState<EContactType>(EContactType.XEPPT);

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    const { data: searchContacts } = useApiQuery({
        method: () => apiContactService.searchContact(requestSearch),
        deps: [requestSearch],
        condition: requestSearch.length >= 3
    });

    const { handleRequest: handleSaveContact } = useApiMutation({
        method: (val: string) => {
            return apiContactService.addContact(val);
        },
        onSuccess: () => {
            handleBack();
            requestSuccessLocale('add_contact');
        },
        onError: requestErrorLocale
    });

    useEffect(() => {
        if (!!editableContact) {
            form.reset(editableContact);
            setIsEmail(!!editableContact?.email);
            setType(EContactType.INTERAC);
        }
    }, []);

    const { handleRequest: handleCreateContact, isLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const data: TCreateContact = {
                firstName: values?.firstName,
                lastName: values?.lastName,
                phone: undefined,
                email: undefined
            };
            if (isEmail) {
                data.email = values?.email;
            } else {
                data.phone = values?.phone;
            }
            return apiContactService.createContact(data);
        },
        onSuccess: () => {
            requestSuccessLocale('create_contact');
            handleBack();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleUpdateContact, isLoading: isUpdateContactLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                const data: TCreateContact = {
                    firstName: values?.firstName,
                    lastName: values?.lastName
                };
                return apiContactService.updateContact(editableContact?.id || '', data);
            },
            onSuccess: () => {
                if (!!editableContact) {
                    requestSuccessLocale('update_contact');
                } else {
                    requestSuccessLocale('create_contact');
                }
                handleBack();
            },
            onError: requestErrorLocale
        });

    const onSubmit = () => {
        form.trigger().then((isValid) => {
            if (isValid) {
                if (editableContact) {
                    handleUpdateContact(undefined);
                } else {
                    handleCreateContact(undefined);
                }
            }
        });
    };

    return (
        <FormProvider {...form}>
            <motion.div {...pageAnimation} className={styles.content}>
                <div className={styles.form_row}>
                    <div className={styles.section} onClick={() => setType(EContactType.XEPPT)}>
                        <Radio
                            className={styles.radio}
                            small
                            disabled={!!editableContact}
                            checked={type === EContactType.XEPPT}
                        />
                        <Typography>XEPPT Contact</Typography>
                    </div>
                    <div className={styles.section} onClick={() => setType(EContactType.INTERAC)}>
                        <Radio
                            className={styles.radio}
                            small
                            disabled={!!editableContact}
                            checked={type === EContactType.INTERAC}
                        />
                        <Typography>Interac Contact</Typography>
                    </div>
                </div>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {t(
                        !!editableContact ? 'update_contact_description' : 'add_contact_description'
                    )}
                </Typography>
                {type === EContactType.XEPPT && !editableContact && (
                    <div className={styles.content}>
                        <SearchSelect
                            search={search}
                            onChangeSearch={(val) => setSearch(val)}
                            full
                            isButton
                            buttonLabel="Add XEPPT contact"
                            onButtonClick={(val) => {
                                handleSaveContact(val as string);
                            }}
                            label={t('search_contact')}
                            items={convertContactsListToOptions(searchContacts)}
                        />
                    </div>
                )}
                {type === EContactType.INTERAC && (
                    <div className={styles.form_row}>
                        <FormField
                            name="firstName"
                            renderComponent={(props) => (
                                <Input label={labelLocale('first_name')} full {...props} />
                            )}
                            rules={{
                                required: { value: true, message: validationLocale('firstName') }
                            }}
                        />
                        <FormField
                            name="lastName"
                            renderComponent={(props) => (
                                <Input label={labelLocale('last_name')} full {...props} />
                            )}
                            rules={{
                                required: { value: true, message: validationLocale('lastName') }
                            }}
                        />
                    </div>
                )}
                {type === EContactType.INTERAC && (
                    <div className={styles.content}>
                        <Typography variant="body3" className={styles.ddr_info}>
                            {t('notify_1')} <span>{t('notify_2')}</span> {t('notify_3')}
                        </Typography>
                        {((!!editableContact && !!editableContact?.email) || !editableContact) && (
                            <div className={styles.form_row}>
                                <Radio
                                    className={styles.radio}
                                    small
                                    disabled={!!editableContact}
                                    checked={isEmail}
                                    onClick={() => setIsEmail(true)}
                                />
                                <FormField
                                    name="email"
                                    renderComponent={(props) => (
                                        <Input
                                            label={labelLocale('email')}
                                            full
                                            readOnly={!isEmail || !!editableContact}
                                            disabled={!isEmail}
                                            {...props}
                                        />
                                    )}
                                    rules={{
                                        required: {
                                            value: isEmail && !editableContact,
                                            message: validationLocale('email')
                                        }
                                    }}
                                />
                                {!editableContact && (
                                    <FormField
                                        name="confirmEmail"
                                        renderComponent={(props) => (
                                            <Input
                                                disabled={!isEmail || !!editableContact}
                                                label={labelLocale('confirm_email')}
                                                full
                                                readOnly={!isEmail || !!editableContact}
                                                {...props}
                                            />
                                        )}
                                        rules={{
                                            required: {
                                                value: isEmail && !editableContact,
                                                message: validationLocale('emailConfirm')
                                            },
                                            validate: (val: string) => {
                                                if (!!editableContact) {
                                                    return undefined;
                                                }
                                                return (
                                                    val === form.getValues('email') ||
                                                    validationLocale('emailConfirm')
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        {/*//@ts-ignore*/}
                        {((!!editableContact && !!editableContact?.phone) || !editableContact) && (
                            <div className={styles.form_row}>
                                <Radio
                                    className={styles.radio}
                                    small
                                    disabled={!!editableContact}
                                    checked={!isEmail}
                                    onClick={() => setIsEmail(false)}
                                />
                                <FormField
                                    name="phone"
                                    renderComponent={(props) => (
                                        <Input
                                            label={labelLocale('enter_contact_phone')}
                                            full
                                            readOnly={isEmail || !!editableContact}
                                            disabled={isEmail}
                                            {...props}
                                            mask="(999) 999 99 99"
                                        />
                                    )}
                                    rules={{
                                        required: {
                                            value: !isEmail && !editableContact,
                                            message: validationLocale('phone')
                                        }
                                    }}
                                />
                                {!editableContact && (
                                    <FormField
                                        name="confirmPhone"
                                        renderComponent={(props) => (
                                            <Input
                                                disabled={isEmail || !!editableContact}
                                                label={labelLocale('enter_contact_confirm_phone')}
                                                full
                                                readOnly={isEmail || !!editableContact}
                                                {...props}
                                                mask="(999) 999 99 99"
                                            />
                                        )}
                                        rules={{
                                            required: {
                                                value: !isEmail && !editableContact,
                                                message: validationLocale('phone')
                                            },
                                            validate: (val: string) => {
                                                if (!!editableContact) {
                                                    return undefined;
                                                }
                                                return (
                                                    val === form.getValues('phone') ||
                                                    validationLocale('verify_contact_form')
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className={styles.actions}>
                    <Button
                        leftIcon="arrow_left"
                        variant="outlined-dark"
                        size="normal"
                        onClick={handleBack}>
                        {submitLocale('back')}
                    </Button>
                    {(type === EContactType.INTERAC || !!editableContact) && (
                        <Button
                            variant="primary"
                            size="normal"
                            onClick={onSubmit}
                            isLoading={isLoading || isUpdateContactLoading}>
                            {t(!!editableContact ? 'update_contact_submit' : 'add_contact_submit')}
                        </Button>
                    )}
                </div>
            </motion.div>
        </FormProvider>
    );
};

export default Contact;
