import React, { FC, useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import TableTag from '@components/table_tag';
import DatePicker from '@components/date_picker';
import MoneyDirectionPicker from '@components/money_direction_picker';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { ETransactionAction, TTransactionsFilter } from '@xeppt/xeppt-sdk/types/transaction';
import {
    moneyInTransactions,
    moneyOutTransactions,
    transactionsActionsFilterArray
} from '@const/transactions';
import { getDisableFeature } from '@utils/index';

interface IProps {
    toggleFilter: () => void;
    onChangeFilter: (val: TTransactionsFilter) => void;
}

const FilterSection: FC<IProps> = ({ toggleFilter, onChangeFilter }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'transactions.filter'
    });
    const { labelLocale } = useLocales();
    const [dateFilter, setDateFilter] = useState('');
    const [dateFrom, setDateFrom] = useState<string>();
    const [dateTo, setDateTo] = useState<string>();
    const [typeFilter, setTypeFilter] = useState<ETransactionAction[]>([]);
    const [activeDirection, setActionDirection] = useState('all');

    const handleChangeDateType = (value: string) => {
        if (dateFilter === value) {
            setDateFilter('');
            setDateFrom(undefined);
            setDateTo(undefined);
        } else {
            setDateFilter(value);
        }
        if (value === 'week') {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            setDateFrom(date.toString());
            setDateTo(new Date().toString());
        } else if (value === 'month') {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            setDateFrom(date.toString());
            setDateTo(new Date().toString());
        } else if (value === 'year') {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 1);
            setDateFrom(date.toString());
            setDateTo(new Date().toString());
        }
    };

    const handleChangeType = (value: ETransactionAction) => {
        if (typeFilter.includes(value)) {
            setTypeFilter((state) => state.filter((item) => item !== value));
        } else {
            setTypeFilter((state) => [...state, value]);
        }
    };

    const handleChangeDirection = (value: string) => {
        setActionDirection(value);
        if (value === 'all') {
            setTypeFilter([]);
        } else if (value === 'money_in') {
            setTypeFilter(moneyInTransactions);
        } else {
            setTypeFilter(moneyOutTransactions);
        }
    };

    useMemo(() => {
        if (moneyInTransactions.every((action) => typeFilter.includes(action))) {
            setActionDirection('money_in');
        } else if (moneyOutTransactions.every((action) => typeFilter.includes(action))) {
            setActionDirection('money_out');
        } else if (typeFilter.length === 0 || typeFilter.length === 4) {
            setActionDirection('all');
        }
    }, [typeFilter]);

    useEffect(() => {
        if (dateTo && dateFrom) {
            onChangeFilter({
                action: typeFilter,
                dateTo: new Date(dateTo),
                dateFrom: new Date(dateFrom)
            });
        } else if (typeFilter) {
            onChangeFilter({
                action: typeFilter
            });
        }
    }, [dateFilter, typeFilter, activeDirection, dateFrom, dateTo]);

    const transactionsActionsFilter = useMemo(() => {
        return transactionsActionsFilterArray(t);
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.title_wrapper}>
                <Typography variant="h4">Filters</Typography>
                <button onClick={toggleFilter}>
                    <Icon name="close" />
                </button>
            </div>
            <div className={styles.block_wrapper}>
                <Typography variant="h4">Date</Typography>
                <div className={styles.tags_wrapper}>
                    <TableTag
                        variant="outlined-dark"
                        isActive={dateFilter === 'week'}
                        onClick={() => handleChangeDateType('week')}>
                        {t('last_week')}
                    </TableTag>
                    <TableTag
                        variant="outlined-dark"
                        isActive={dateFilter === 'month'}
                        onClick={() => handleChangeDateType('month')}>
                        {t('last_month')}
                    </TableTag>
                    <TableTag
                        variant="outlined-dark"
                        isActive={dateFilter === 'year'}
                        onClick={() => handleChangeDateType('year')}>
                        {t('last_year')}
                    </TableTag>
                </div>
                <DatePicker
                    value={dateFrom}
                    onChange={(val) => {
                        setDateFrom(val);
                        setDateFilter('');
                    }}
                    maxDate={getDisableFeature()}
                    isDisableFeature
                    full
                    label={labelLocale('from_date')}
                />
                <DatePicker
                    value={dateTo}
                    onChange={(val) => {
                        setDateTo(val);
                        setDateFilter('');
                    }}
                    maxDate={getDisableFeature()}
                    isDisableFeature
                    full
                    label={labelLocale('to_date')}
                />
            </div>
            <div className={styles.block_wrapper}>
                <Typography variant="h4">{t('type')}</Typography>
                <div className={styles.tags_wrapper}>
                    <TableTag
                        variant="outlined-dark"
                        isActive={typeFilter.length === 0}
                        onClick={() => setTypeFilter([])}>
                        {t('all')}
                    </TableTag>
                    {transactionsActionsFilter.map((item) => {
                        return (
                            <TableTag
                                key={item.action}
                                variant="outlined-dark"
                                isActive={typeFilter.includes(item.action)}
                                onClick={() => handleChangeType(item.action)}>
                                {item.label}
                            </TableTag>
                        );
                    })}
                </div>
                <MoneyDirectionPicker active={activeDirection} onChange={handleChangeDirection} />
            </div>
        </div>
    );
};

export default FilterSection;
