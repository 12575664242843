export enum EOrderStatus {
    PROGRESS = 'In progress',
    LOCKED = 'locked',
    FINISH = 'finish'
}

export const enum ECardType {
    PHYSICAL = 'primary',
    VIRTUAL = 'virtual'
}

export enum EPaymentSystem {
    VISA = 'visa',
    MASTERCARD = 'mastercard'
}

export enum EAccountType {
    BANK = 'bank',
    CARD = 'card'
}

export enum ECreditCardBrands {
    AMEX = 'amex',
    VISA = 'visa',
    MASTERCARD = 'mastercard',
    DISCOVER = 'discover'
}

export enum EActiveETransfer {
    SEND = 'send money',
    REQUEST = 'request money',
    CONTACTS = 'manage contacts',
    AUTODEPOSIT = 'interac autodeposit'
}

export enum EActivePayBills {
    PAY = 'pay bills',
    MANAGE = 'manage payees',
    SCHEDULE = 'schedule payments'
}

export enum ESchedulePaymentType {
    PAYMENTS_DATE = 1,
    NUMBER_OF_PAYMENTS = 2
}

export enum ESendRequestMoneyMethod {
    INTERNAL = '1',
    E_TRANSFER = '2',
    BANK = '3',
    E_TRANSFER_REQUEST = '4',
    INTERNAL_REQUEST = '5',
    BANK_REQUEST = '6'
}

export enum ENewNotificationType {
    TRANSACTION = 'transaction',
    CARD = 'card',
    BILL = 'bill',
    ACCOUNT = 'account',
    USER = 'user',
    PHONE = 'phone',
    EMAIL = 'email',
    LOGOUT = 'logout'
}

export enum ENotificationCategory {
    CARD = 'card',
    PERSONAL = 'personal',
    PAYMENTS = 'payments',
    SCHEDULE = 'schedule',
    MARKETING = 'marketing'
}

export enum EContactViewType {
    BLOCKED = 'blocked_contacts',
    CONTACTS = 'contacts'
}
