import { useProgressBar } from '@hooks/helpers/useProgressBar';
import Info from '@sections/send_and_request/send_money/info';
import Success from '@sections/send_and_request/send_money/success';
import Verification from '@sections/send_and_request/send_money/verification';
import styles from './styles.module.scss';
import ProgressBar, { TProgressItem } from '@components/progress_bar';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import { apiAccountService, apiContactService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { eTransferSendMoneyProgressSteps } from '@const/progress_bar_steps';
import { useEffect, useState } from 'react';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import { EPaymentFrequency } from '@xeppt/xeppt-sdk/types/billing';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { TContact } from '@xeppt/xeppt-sdk/types';
import ChooseMethodSection from '@sections/send_and_request/choose_methods/method';
import { useTranslation } from 'react-i18next';
import { EContactType } from '@xeppt/xeppt-sdk/types/wallet';
import { useQueryParams } from '@hooks/helpers/useQueryParams';

const SendMoneySection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(
        eTransferSendMoneyProgressSteps
    );
    const [type, contactId] = useQueryParams(['type', 'contactId']);
    const formMethods = useForm({
        defaultValues: {
            paymentType: ESchedulePaymentType.PAYMENTS_DATE,
            contact: '',
            amount: undefined,
            securityQuestion: '',
            securityAnswer: '',
            account: '',
            message: '',
            paymentFrequency: EPaymentFrequency.ONCE,
            nextPaymentAt: new Date()
        }
    });
    const { refetchAccount } = useUserContext();
    const [sendMethod, setSendMethod] = useState<ESendRequestMoneyMethod>(
        ESendRequestMoneyMethod.INTERNAL
    );
    const [contactsList, setContactsList] = useState<TContact[]>([]);
    const [isMethodChosen, setIsMethodChosen] = useState(false);

    useEffect(() => {
        if (type) {
            //@ts-ignore
            setSendMethod(type as ESendRequestMoneyMethod);
            setIsMethodChosen(true);
        }
        if (contactId) {
            formMethods.setValue('contact', contactId);
        }
    }, [type, contactId, contactsList]);

    const { handleRequest: handleSendMoneyByETransfer, isLoading: isEtransferRequestLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                return apiAccountService.sendMoneyETransfer({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    securityQuestion: values.securityQuestion,
                    securityAnswer: values.securityAnswer,
                    message: values.message
                });
            },
            onSuccess: () => {
                requestSuccessLocale('send_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleSendMoneyByWallet, isLoading: isWalletRequestLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                return apiAccountService.sendMoneyWallet({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    message: values.message
                });
            },
            onSuccess: () => {
                requestSuccessLocale('send_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleSendMoneyByEft, isLoading: isEftRequestLoading } = useApiMutation({
        method: () => {
            const values = formMethods.getValues();
            return apiAccountService.sendMoneyEft({
                id: values.account,
                amount: Number(values.amount)
            });
        },
        onSuccess: () => {
            requestSuccessLocale('send_money');
            nextStep();
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.E_TRANSFER:
                handleSendMoneyByETransfer(undefined);
                break;
            case ESendRequestMoneyMethod.INTERNAL:
                handleSendMoneyByWallet(undefined);
                break;
            case ESendRequestMoneyMethod.BANK:
                handleSendMoneyByEft(undefined);
                break;
        }
    };

    const { data: contacts, handleRequest: refetchContacts } = useApiQuery({
        method: () => apiContactService.getContacts()
    });

    useEffect(() => {
        if (contacts) {
            if (sendMethod === ESendRequestMoneyMethod.E_TRANSFER) {
                setContactsList(contacts.filter((item) => item.type === EContactType.INTERAC));
            } else {
                setContactsList(contacts.filter((item) => item.type === EContactType.XEPPT));
            }
        }
    }, [sendMethod, contacts]);

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <Info
                        method={sendMethod}
                        onNextStep={nextStep}
                        refetchContacts={refetchContacts}
                        onPrevStep={() => setIsMethodChosen(false)}
                        contactsList={contactsList || []}
                    />
                );
            case '2':
                return (
                    <Verification
                        contactList={contactsList}
                        isLoading={
                            isWalletRequestLoading ||
                            isEftRequestLoading ||
                            isEtransferRequestLoading
                        }
                        onSubmit={onSubmit}
                        onPrevStep={prevStep}
                        method={sendMethod}
                    />
                );
            default:
                return <Success onRepeat={() => changeStep('1')} />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            {isMethodChosen ? (
                <>
                    <div className={styles.progress_wrapper}>
                        <div className={styles.content}>
                            <ProgressBar
                                variant="light"
                                size="large"
                                steps={
                                    eTransferSendMoneyProgressSteps.filter(
                                        (item) => item !== undefined
                                    ) as TProgressItem[]
                                }
                                availableStep={availableStep}
                                currentStep={currentStep}
                                className={styles.progress}
                            />
                        </div>
                    </div>
                    <FormProvider {...formMethods}>{renderContent()}</FormProvider>
                </>
            ) : (
                <ChooseMethodSection
                    method={sendMethod}
                    onChangeMethod={(val) => setSendMethod(val)}
                    onSubmit={() => setIsMethodChosen(true)}
                    title={t('title')}
                    type="send"
                    styles={styles}
                />
            )}
        </motion.div>
    );
};

export default SendMoneySection;
