import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';

interface IProps {
    onSubmit: () => void;
    onClose: () => void;
}

const KycInfo: FC<IProps> = ({ onSubmit }) => {
    const { t } = useTranslation('shufti_pro');
    const { submitLocale } = useLocales();
    const { user } = useUserContext();

    return (
        <>
            <Typography variant="h3">
                {t('info_title_hi')} {user?.profile.firstName}, {t('info_title_join')}
            </Typography>
            <Typography variant="body3">{t('info_description')}</Typography>
            <div className={styles.steps_wrapper}>
                <div className={styles.step_wrapper}>
                    <div className={styles.step_number}>1</div>
                    <div className={styles.step_text}>
                        <Typography className={styles.step_title} variant="h4">
                            {t('info_steps.first_title')}
                        </Typography>
                        <Typography className={styles.step_description} variant="body3">
                            {t('info_steps.first_description')}
                        </Typography>
                    </div>
                </div>
                <div className={styles.step_wrapper}>
                    <div className={styles.step_number}>2</div>
                    <div className={styles.step_text}>
                        <Typography className={styles.step_title} variant="h4">
                            {t('info_steps.second_title')}
                        </Typography>
                        <Typography className={styles.step_description} variant="body3">
                            {t('info_steps.second_description')}
                        </Typography>
                    </div>
                </div>
                <div className={styles.step_wrapper}>
                    <div className={styles.step_number}>3</div>
                    <div className={styles.step_text}>
                        <Typography className={styles.step_title} variant="h4">
                            {t('info_steps.third_title')}
                        </Typography>
                        <Typography className={styles.step_description} variant="body3">
                            {t('info_steps.third_description')}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={styles.actions}>
                <Button size="normal" variant="primary" onClick={onSubmit}>
                    {submitLocale('start')}
                </Button>
            </div>
        </>
    );
};

export default KycInfo;
