import React from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import TransactionTable from '@components/transactions_table';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { ENewNotificationType } from '@enum';

const TransactionsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.transactions'
    });
    const navigate = useNavigate();

    const { data: transactions, isLoading: isTransactionsLoading } = useApiQuery({
        method: () => apiAccountService.findTransactions({}, { cursor: undefined, limit: 3 }),
        notificationType: ENewNotificationType.TRANSACTION
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.table_header}>
                    <div className={styles.left}>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                    <Button
                        variant="outlined-dark"
                        size="medium"
                        onClick={() => navigate(routes.transactions)}>
                        {t('list.show_more')}
                    </Button>
                </div>
                <TransactionTable
                    isTransactionsLoading={isTransactionsLoading}
                    transactions={transactions?.data || []}
                />
            </div>
        </div>
    );
};

export default TransactionsSection;
