import React, { FC, useEffect, useMemo, useState } from 'react';
import useCollapse from '@hooks/helpers/useCollapse';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import FormField from '@components/form_field';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { ESendRequestMoneyMethod } from '@enum';
import Select from '@components/common/select';
import { EDdrStatus, TContact } from '@xeppt/xeppt-sdk/types';
import { apiAccountService, apiContactService } from '@api';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useUserContext } from '@hooks/context/useUserContext';
import Skeleton from '@components/common/skeleton';
import SearchSelect from '@components/common/search_select';
import { useDebounceValue } from 'usehooks-ts';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useInteracModals } from '@hooks/modals/useInteracModals';
import {
    convertContactsListToOptions,
    excludeExistedContacts,
    searchByContacts
} from '@utils/index';
import Textarea from '@components/common/textarea';

interface IProps {
    onNextStep: () => void;
    onPrevStep: () => void;
    refetchContacts: () => void;
    contactList: TContact[];
    method: ESendRequestMoneyMethod;
}

const Info: FC<IProps> = ({ onNextStep, onPrevStep, method, contactList, refetchContacts }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.request_money.info'
    });
    const { labelLocale, submitLocale, validationLocale, requestErrorLocale } = useLocales();
    const { isOpened, onToggle, contentStyles, contentRef } = useCollapse(true);
    const { user, account } = useUserContext();
    const navigate = useNavigate();
    const form = useFormContext();
    const { handleChangeInteracEmail } = useInteracModals();
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);
    const [search, setSearch] = useState('');
    const isBankRequest = useMemo(() => method === ESendRequestMoneyMethod.BANK_REQUEST, [method]);
    const isWalletRequest = useMemo(
        () => method === ESendRequestMoneyMethod.INTERNAL_REQUEST,
        [method]
    );
    const isETransferRequest = useMemo(
        () => method === ESendRequestMoneyMethod.E_TRANSFER_REQUEST,
        [method]
    );

    const { data: searchContacts } = useApiQuery({
        method: () => apiContactService.searchContact(requestSearch),
        deps: [requestSearch],
        condition: requestSearch.length >= 3
    });

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    const { data: ddrStatus, isLoading: isDdrLoading } = useApiQuery({
        method: () => apiAccountService.checkETransferDdr(),
        condition: isETransferRequest
    });

    const { handleRequest: handleSaveContact } = useApiMutation({
        method: () => {
            const values = form.getValues();
            return apiContactService.addContact(values.contact);
        },
        onSuccess: (data) => {
            form.setValue('contact', data.id);
            refetchContacts();
            onNextStep();
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        const values = form.getValues();
        form.trigger().then((isValid) => {
            const foundUserSearch = searchContacts?.find((item) => item.id === values.contact);
            const foundUserContact =
                contactList.find((item) => item.tag === foundUserSearch?.tag) ||
                contactList.find((item) => item.id === values?.contact);
            if (isValid) {
                if (isWalletRequest && !foundUserContact) {
                    handleSaveContact(undefined);
                } else {
                    form.setValue('contact', foundUserContact?.id);
                    onNextStep();
                }
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {t(isETransferRequest ? 'description' : 'description_internal')}
                </Typography>
            </div>
            <div className={styles.content}>
                <FormField
                    name="amount"
                    renderComponent={(props) => (
                        <Input
                            full
                            label={labelLocale('amount')}
                            prefix="CAD"
                            rightIcon="canadian"
                            fixedAmount
                            hideErrorIcon
                            onlyNumbers
                            {...props}
                        />
                    )}
                    rules={{ required: { value: true, message: validationLocale('amount') } }}
                />
                {method !== ESendRequestMoneyMethod.BANK_REQUEST && (
                    <FormField
                        name="message"
                        renderComponent={(props) => (
                            <Textarea
                                full
                                label={labelLocale('message_optional')}
                                maxLength={250}
                                {...props}
                            />
                        )}
                    />
                )}
            </div>
            {method !== ESendRequestMoneyMethod.BANK_REQUEST && (
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('from')}</Typography>
                        <div className={styles.actions}>
                            <Button
                                variant="outlined-dark"
                                size="small"
                                onClick={() => navigate(routes.send_and_request_contacts)}>
                                {submitLocale('add_new')}
                            </Button>
                        </div>
                    </div>
                    {isETransferRequest && (
                        <FormField<string | number>
                            name="contact"
                            renderComponent={(props) => (
                                <Select
                                    full
                                    label={labelLocale('contact')}
                                    items={
                                        contactList?.map((item) => ({
                                            //@ts-ignore
                                            label: `${item.firstName} ${item.lastName} (${item.email || (item?.phone && `+${item?.phone}`)})`,
                                            value: item.id
                                        })) || []
                                    }
                                    {...props}
                                />
                            )}
                            rules={{
                                required: { value: true, message: validationLocale('contact') }
                            }}
                        />
                    )}
                    {isWalletRequest && (
                        <FormField<string | number>
                            name="contact"
                            renderComponent={(props) => (
                                <SearchSelect
                                    full
                                    label={labelLocale('contact')}
                                    splitArrays={[
                                        {
                                            label: 'Your Contacts',
                                            array: convertContactsListToOptions(
                                                searchByContacts(search, contactList)
                                            )
                                        },
                                        {
                                            label: 'XEPPT Contacts',
                                            array: convertContactsListToOptions(
                                                excludeExistedContacts(contactList, searchContacts)
                                            )
                                        }
                                    ]}
                                    search={search}
                                    onChangeSearch={(val) => setSearch(val)}
                                    {...props}
                                />
                            )}
                            rules={{
                                required: { value: true, message: validationLocale('contact') }
                            }}
                        />
                    )}
                    {isETransferRequest && (
                        <>
                            <div className={styles.content}>
                                <div className={styles.header}>
                                    <Typography variant="h4">{t('by')}</Typography>
                                    <div className={styles.actions}>
                                        <Typography variant="body3">
                                            {user?.profile?.firstName} {user?.profile?.lastName} (
                                            {account?.eTransferAccount?.interacEmail})
                                        </Typography>
                                        <Button
                                            leftIcon="edit"
                                            onClick={handleChangeInteracEmail}
                                        />
                                    </div>
                                </div>
                                {isDdrLoading ? (
                                    <Skeleton className={styles.skeleton} />
                                ) : (
                                    ddrStatus !== EDdrStatus.ENABLED && (
                                        <Typography variant="body3" className={styles.balance}>
                                            {t('autodeposit')}
                                        </Typography>
                                    )
                                )}
                            </div>
                            <div className={styles.submit_wrapper}>
                                {ddrStatus !== EDdrStatus.ENABLED ? (
                                    <Button
                                        variant="primary"
                                        size="normal"
                                        onClick={() =>
                                            navigate(routes.send_and_request_autodeposit)
                                        }>
                                        {submitLocale('go_to_autodeposit')}
                                    </Button>
                                ) : (
                                    <Button variant="primary" size="normal" onClick={onSubmit}>
                                        {submitLocale('next')}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
            {isBankRequest && (
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('from')}</Typography>
                        <div className={styles.actions}>
                            <Button
                                variant="outlined-dark"
                                size="small"
                                onClick={() => navigate(routes.link_card_bank)}>
                                {submitLocale('add_new')}
                            </Button>
                        </div>
                    </div>
                    <FormField<string | number>
                        name="account"
                        renderComponent={(props) => (
                            <Select
                                full
                                label={labelLocale('bank_account')}
                                items={
                                    account?.bankAccounts?.map((item) => ({
                                        label: `${item.institutionName} (${item.accountName} ${item.accountNumber})`,
                                        value: item.id
                                    })) || []
                                }
                                {...props}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('bank_account') }
                        }}
                    />
                </div>
            )}
            <div className={styles.actions}>
                <Button
                    size="normal"
                    leftIcon="arrow_left"
                    variant="outlined-dark"
                    onClick={onPrevStep}>
                    {submitLocale('back')}
                </Button>
                {!isETransferRequest && (
                    <Button variant="primary" size="normal" onClick={onSubmit}>
                        {submitLocale('next')}
                    </Button>
                )}
            </div>
            <div className={styles.fees}>
                <Button onClick={onToggle}>
                    <div className={`${styles.icon} ${isOpened ? styles.isOpen : ''}`} />{' '}
                    <Typography variant="h5">{t('fees')}</Typography>
                </Button>
                <div
                    className={`${styles.fees_container} ${isOpened ? styles.isOpen : ''}`}
                    style={contentStyles}
                    ref={contentRef}>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('fee_personal')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('fee_personal_1')}</Typography>
                            <Typography>{t('fee_personal_2')}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('fee_personal_3')}</Typography>
                            <Typography>$5 {t('fee_personal_4')}</Typography>
                        </div>
                    </div>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('fee_business')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('fee_business_1')}</Typography>
                            <Typography>$1.50 {t('fee_business_2')}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('fee_business_3')}</Typography>
                            <Typography>$5 {t('fee_business_4')}</Typography>
                        </div>
                    </div>
                </div>
                <div className={styles.notes}>
                    {t('notes')}
                    <div className={styles.row}>
                        <span>1.</span>
                        <div>{t('notes_1')}</div>
                    </div>
                    <div className={styles.row}>
                        <span>2.</span>
                        <div>{t('notes_2')}</div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Info;
