import { useProgressBar } from '@hooks/helpers/useProgressBar';
import styles from './styles.module.scss';
import ProgressBar, { TProgressItem } from '@components/progress_bar';
import Info from '@sections/send_and_request/request_money/info';
import Verification from '@sections/send_and_request/request_money/verification';
import Success from '@sections/send_and_request/request_money/success';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import { apiAccountService, apiContactService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { eTransferRequestMoneyProgressSteps } from '@const/progress_bar_steps';
import { useEffect, useState } from 'react';
import { ESendRequestMoneyMethod } from '@enum';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { TContact } from '@xeppt/xeppt-sdk/types';
import ChooseMethodSection from '@sections/send_and_request/choose_methods/method';
import { useTranslation } from 'react-i18next';
import { EContactType } from '@xeppt/xeppt-sdk/types/wallet';
import { useQueryParams } from '@hooks/helpers/useQueryParams';

const RequestMoneySection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money'
    });
    const [type, contactId] = useQueryParams(['type', 'contactId']);
    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(
        eTransferRequestMoneyProgressSteps
    );
    const form = useForm({});
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const [sendMethod, setSendMethod] = useState<ESendRequestMoneyMethod>(
        ESendRequestMoneyMethod.INTERNAL_REQUEST
    );
    const [contactList, setContactsList] = useState<TContact[]>([]);
    const [isMethodChosen, setIsMethodChosen] = useState(false);

    const { data: contacts, handleRequest: refetchContacts } = useApiQuery({
        method: () => apiContactService.getContacts()
    });

    useEffect(() => {
        if (type) {
            //@ts-ignore
            setSendMethod(type as ESendRequestMoneyMethod);
            setIsMethodChosen(true);
        }
        if (contactId) {
            form.setValue('contact', contactId);
        }
    }, [type, contactId, contactList]);

    const { handleRequest: handleRequestMoneyByETransfer, isLoading: isETransferRequestLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                return apiAccountService.requestMoneyETransfer({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    message: values.message,
                    // method: contactList?.find((item) => item.id === values.contact)?.email
                    //     ? 'email'
                    //     : 'phone'
                    method: 'email'
                });
            },
            onSuccess: () => {
                requestSuccessLocale('request_money');
                nextStep();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleRequestMoneyByWallet, isLoading: isWalletRequestLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                return apiAccountService.requestMoneyWallet({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    message: values.message
                });
            },
            onSuccess: () => {
                requestSuccessLocale('request_money');
                nextStep();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleRequestMoneyByEft, isLoading: isEftRequestLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                return apiAccountService.requestMoneyEft({
                    id: values.account,
                    amount: Number(values.amount)
                });
            },
            onSuccess: () => {
                requestSuccessLocale('request_money');
                nextStep();
            },
            onError: requestErrorLocale
        });

    const onSubmit = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.E_TRANSFER_REQUEST:
                handleRequestMoneyByETransfer(undefined);
                break;
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                handleRequestMoneyByWallet(undefined);
                break;
            case ESendRequestMoneyMethod.BANK_REQUEST:
                handleRequestMoneyByEft(undefined);
                break;
        }
    };

    useEffect(() => {
        if (contacts) {
            if (sendMethod === ESendRequestMoneyMethod.E_TRANSFER_REQUEST) {
                setContactsList(contacts.filter((item) => item.type === EContactType.INTERAC));
            } else {
                setContactsList(contacts.filter((item) => item.type === EContactType.XEPPT));
            }
        }
    }, [sendMethod, contacts]);

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <Info
                        onNextStep={nextStep}
                        method={sendMethod}
                        refetchContacts={refetchContacts}
                        contactList={contactList || []}
                        onPrevStep={() => setIsMethodChosen(false)}
                    />
                );
            case '2':
                return (
                    <Verification
                        contactList={contactList || []}
                        isLoading={
                            isETransferRequestLoading ||
                            isWalletRequestLoading ||
                            isEftRequestLoading
                        }
                        onSubmit={onSubmit}
                        onPrevStep={prevStep}
                        method={sendMethod}
                    />
                );
            default:
                return <Success onRepeat={() => changeStep('1')} />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            {isMethodChosen ? (
                <>
                    <div className={styles.progress_wrapper}>
                        <div className={styles.content}>
                            <ProgressBar
                                variant="light"
                                size="large"
                                steps={
                                    eTransferRequestMoneyProgressSteps.filter(
                                        (item) => item !== undefined
                                    ) as TProgressItem[]
                                }
                                availableStep={availableStep}
                                currentStep={currentStep}
                                className={styles.progress}
                            />
                        </div>
                    </div>
                    <FormProvider {...form}>{renderContent()}</FormProvider>
                </>
            ) : (
                <ChooseMethodSection
                    method={sendMethod}
                    onChangeMethod={(val) => setSendMethod(val)}
                    onSubmit={() => setIsMethodChosen(true)}
                    title={t('title')}
                    type="request"
                    styles={styles}
                />
            )}
        </motion.div>
    );
};

export default RequestMoneySection;
