import { useUserContext } from '@hooks/context/useUserContext';
import { EUserStatus } from '@xeppt/xeppt-sdk/types';
import { useKycModals } from '@hooks/modals/useKycModals';

export const useVerificationGuard = () => {
    const { user } = useUserContext();
    const { handleOpenPendingModal, handleOpenKycFirstModal } = useKycModals();

    const verifiedAction = <T = void>(callback?: () => T, unVerifiedCallback?: () => void) => {
        if (user?.status === EUserStatus.PENDING) {
            handleOpenPendingModal();
            unVerifiedCallback && unVerifiedCallback();
        } else if (user?.status !== EUserStatus.UNVERIFIED) {
            return callback && callback();
        } else {
            handleOpenKycFirstModal();
            unVerifiedCallback && unVerifiedCallback();
        }
    };

    return {
        verifiedAction
    };
};
