import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import React, { useState } from 'react';
import Checkbox from '@components/common/checkbox';
import { apiAccountService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import { EDdrStatus } from '@xeppt/xeppt-sdk/types';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useInteracModals } from '@hooks/modals/useInteracModals';
import { useSendAndRequestModals } from '@hooks/modals/useSendAndRequestModals';

const ManageAutodepositSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.manage_autodeposit'
    });
    const [isUpdate, setIsUpdate] = useState(false);
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const { user, account, refetchAccount } = useUserContext();
    const { handleChangeInteracEmail } = useInteracModals();
    const [isAgree, setIsAgree] = useState(false);
    const { submitLocale } = useLocales();
    const { handleOpenRequestAutodepositModal } = useSendAndRequestModals();

    const { handleRequest: onSetAutodeposit, isLoading } = useApiMutation({
        method: () => {
            return new Promise((res) => {
                apiAccountService.requestETransferDdr();
                setTimeout(() => {
                    res('ok');
                }, 2000);
            });
        },
        onSuccess: () => {
            requestSuccessLocale('set_ddr');
            refetchAccount();
            handleOpenRequestAutodepositModal((val) => setIsUpdate(val));
        },
        onError: requestErrorLocale
    });

    const { data: ddrStatus } = useApiQuery({
        method: () => apiAccountService.checkETransferDdr()
    });

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                </div>
                <div className={styles.content}>
                    <Typography variant="body3" className={styles.send_disclaimer}>
                        {t('description')}
                    </Typography>
                </div>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('interac_email')}</Typography>
                        <div className={styles.actions}>
                            <Typography variant="body3">
                                {user?.profile?.firstName} {user?.profile?.lastName} (
                                {account?.eTransferAccount?.interacEmail})
                            </Typography>
                            <Button leftIcon="edit" onClick={handleChangeInteracEmail} />
                        </div>
                    </div>
                </div>
                {isUpdate ? (
                    <>
                        <motion.div {...pageAnimation} className={styles.content}>
                            <div className={styles.form_row}>
                                <Checkbox
                                    className={styles.checkbox}
                                    checked={isAgree}
                                    onClick={() => setIsAgree((state) => !state)}
                                />
                                <Typography className={styles.send_disclaimer}>
                                    <span>{t('agree_1')}</span>
                                    <br />
                                    {t('agree_2')} <span>{t('agree_3')}</span> {t('agree_4')}
                                    <br />
                                    <br />
                                    {t('agree_5')}
                                    <br />
                                    <br />
                                    {t('agree_6')} <a href="#">{t('agree_7')}</a>
                                </Typography>
                            </div>
                        </motion.div>
                        <Button
                            variant="primary"
                            size="normal"
                            onClick={() => onSetAutodeposit(undefined)}
                            isLoading={isLoading}
                            disabled={!isAgree}>
                            {submitLocale('save')}
                        </Button>
                    </>
                ) : (
                    !!ddrStatus &&
                    ddrStatus !== EDdrStatus.ENABLED && (
                        <Button variant="primary" size="normal" onClick={() => setIsUpdate(true)}>
                            {t('set_up')}
                        </Button>
                    )
                )}
            </div>
        </motion.div>
    );
};

export default ManageAutodepositSection;
