import React, { FC } from 'react';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
    type: 'pay' | 'redeem';
}

const AmountSection: FC<IProps> = ({ type }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'cashback'
    });
    return (
        <>
            <div className={styles.amount_wrapper}>
                <div className={styles.amount_title}>{t('amount')}</div>
                <div className={styles.amount}>$110.00</div>
            </div>
            <Input
                label={t(type === 'pay' ? t('amount_label_pay') : t('amount_label_redeem')).replace(
                    'cashback.',
                    ''
                )}
                full
                // value={modalData?.amount}
                onlyNumbers
                // onChange={(val) => {
                //     changeModalData({ ...modalData, amount: val });
                // }}
                rightIcon="canadian"
                fixedAmount
                prefix="CAD"
            />
        </>
    );
};

export default AmountSection;
