import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { EUserType, TContact, TSearchContact } from '@xeppt/xeppt-sdk/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useUserContext } from '@hooks/context/useUserContext';
import { eTransferBusinessFees, eTransferIndividualFees } from '@const/fees';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { ESendRequestMoneyMethod } from '@enum';

interface IProps {
    onSubmit: () => void;
    isLoading: boolean;
    onPrevStep: () => void;
    method: ESendRequestMoneyMethod;
    contactList: (TContact | TSearchContact)[];
}

const Verification: FC<IProps> = ({ onSubmit, onPrevStep, contactList, isLoading, method }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.request_money.verification'
    });
    const { submitLocale } = useLocales();
    const { getValues, control } = useFormContext();
    const contactId = useWatch({ name: 'contact', control });
    const accountId = useWatch({ name: 'account', control });
    const { user, account } = useUserContext();

    const fees = useMemo(() => {
        if (user?.type === EUserType.INDIVIDUAL) {
            return eTransferIndividualFees;
        }
        return eTransferBusinessFees;
    }, [user]);

    const foundContact = useMemo(() => {
        return contactList.find((item) => item.id === contactId);
    }, [contactList, contactId]);

    const foundAccount = useMemo(() => {
        return account?.bankAccounts?.find((item) => item.id === accountId);
    }, [accountId]);

    const renderRecipient = () => {
        switch (method) {
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
            case ESendRequestMoneyMethod.E_TRANSFER_REQUEST:
                return (
                    <>
                        {foundContact?.firstName} {foundContact?.lastName} ({/*//@ts-ignore*/}
                        {foundContact?.email || foundContact?.phone})
                    </>
                );
            case ESendRequestMoneyMethod.BANK_REQUEST:
                return foundAccount
                    ? `${foundAccount.institutionName} (${foundAccount.accountName} ${foundAccount.accountNumber})`
                    : 'Bank Account';
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.ddr_info}>{t('title')}</div>
                <div className={`${styles.fees_container} ${styles.isOpen}`}>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('summary')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('info_1')}</Typography>
                            <Typography>{renderRecipient()}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('info_2')}</Typography>
                            <Typography>
                                ${(Number(getValues()?.amount) - fees.send).toFixed(2)} CAD
                            </Typography>
                        </div>
                        {method === ESendRequestMoneyMethod.E_TRANSFER_REQUEST && (
                            <div className={styles.row}>
                                <Typography>{t('info_3')}</Typography>
                                <Typography>
                                    {user?.profile.firstName} {user?.profile.lastName} (
                                    {account?.eTransferAccount?.interacEmail})
                                </Typography>
                            </div>
                        )}
                        <div className={styles.row}>
                            <Typography>{t('info_4')}</Typography>
                            <Typography>$0.00</Typography>
                        </div>
                        {method !== ESendRequestMoneyMethod.BANK_REQUEST && (
                            <div className={styles.row}>
                                <Typography>{t('info_5')}</Typography>
                                <Typography>{getValues()?.message || '-'}</Typography>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.notes}>
                    {t('notes_1')}
                    <div className={styles.row}>
                        <span>1.</span>
                        <div>{t('notes_2')}</div>
                    </div>
                    <div className={styles.row}>
                        <span>2.</span>
                        <div>{t('notes_3')}</div>
                    </div>
                    <div className={styles.row}>
                        <span>3.</span>
                        <div>{t('notes_4')}</div>
                    </div>
                </div>
            </div>
            <div className={styles.actions}>
                <Button
                    leftIcon="arrow_left"
                    onClick={onPrevStep}
                    variant="outlined-dark"
                    size="normal">
                    {submitLocale('back')}
                </Button>
                <Button variant="primary" size="normal" onClick={onSubmit} isLoading={isLoading}>
                    {submitLocale('request_money')}
                </Button>
            </div>
        </motion.div>
    );
};

export default Verification;
