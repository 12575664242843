import React, { useEffect, useRef, useState } from 'react';
import logoIcon from '@svg/header_logo.svg';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';
import { getFirstLetter } from '@utils/index';
import styles from './styles.module.scss';
import NavLink from '@components/common/navlink';
import Button from '@components/common/button';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import { routes } from '@const/routes';
import useClickOutside from '@hooks/helpers/useClickOutside';
import { useLocales } from '@hooks/helpers/useLocales';
import { EUserStatus } from '@xeppt/xeppt-sdk/types';
import { useNotificationsContext } from '@hooks/context/useNotificationsContext';
import { useKycModals } from '@hooks/modals/useKycModals';
import NotificationsSidebar from '@sections/notifications';

const cx = classNames.bind(styles);

const Header = () => {
    const profileRef = useRef(null);
    const mobileRef = useRef(null);
    const location = useLocation();
    const [isOpenMobileMenu, setStateIsOpenMobileMenu] = useState(false);
    const [isOpenProfile, setIsOpenProfile] = useState(false);
    const [isOpenNotifications, setIsOpenNotifications] = useState(false);
    const { user, handleLogout } = useUserContext();
    const { notifications, refetchNotifications } = useNotificationsContext();
    const { t, i18n } = useTranslation('header');
    const { submitLocale } = useLocales();
    const { handleOpenKycFirstModal } = useKycModals();

    const handleChangeLanguage = () => {
        const lang = i18n.language === 'en' ? 'fr' : 'en';
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    };

    const toggleUserProfile = () => {
        setIsOpenProfile((state) => !state);
    };

    const toggleMobileMenu = () => {
        setStateIsOpenMobileMenu((state) => !state);
    };

    const toggleNotifications = () => {
        setIsOpenNotifications((state) => !state);
    };

    useEffect(() => {
        setIsOpenProfile(false);
        setStateIsOpenMobileMenu(false);
        setIsOpenNotifications(false);
    }, [location]);

    useClickOutside({
        ref: mobileRef,
        callback: () => {
            setStateIsOpenMobileMenu(false);
        },
        event: 'mousedown'
    });

    useClickOutside({
        ref: profileRef,
        callback: () => {
            setIsOpenProfile(false);
        },
        event: 'mousedown'
    });

    useEffect(() => {
        if (isOpenNotifications) {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
        } else {
            refetchNotifications();
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpenNotifications]);

    return (
        <>
            <header className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.mobile_menu} ref={mobileRef}>
                        <button
                            className={cx([styles.burger, { isOpen: isOpenMobileMenu }])}
                            onClick={toggleMobileMenu}>
                            <div />
                            <div />
                            <div />
                        </button>
                        <div className={cx([styles.dropdown, { isOpen: isOpenMobileMenu }])}>
                            <NavLink className={styles.action} href={routes.dashboard}>
                                <Typography variant="body3">{t('home')}</Typography>
                            </NavLink>
                            <NavLink className={styles.action} href={routes.send_and_request}>
                                <Typography variant="body3">{t('send_and_request')}</Typography>
                            </NavLink>
                            <NavLink className={styles.action} href={routes.pay_bills}>
                                <Typography variant="body3">{t('pay_bills')}</Typography>
                            </NavLink>
                            <div className={styles.divider} />
                            <Typography className={styles.name} variant="body3">
                                {user?.profile?.firstName} {user?.profile?.lastName}
                            </Typography>
                            <NavLink className={styles.action} href={routes.settings}>
                                <Typography variant="body3">{t('settings')}</Typography>
                            </NavLink>
                            {/*<NavLink className={styles.action} href={routes.help_center}>*/}
                            {/*    <Typography variant="body3">{t('help_center')}</Typography>*/}
                            {/*</NavLink>*/}
                            <Button className={styles.action} onClick={handleLogout}>
                                <Typography variant="body3">{t('logout')}</Typography>
                            </Button>
                        </div>
                    </div>
                    <div className={styles.left}>
                        <Link to={routes.dashboard}>
                            <img src={logoIcon} alt="logo" />
                        </Link>
                        <nav className={styles.navigation}>
                            <ul>
                                <li>
                                    <NavLink href={routes.dashboard}>{t('home')}</NavLink>
                                </li>
                                <li>
                                    <NavLink href={routes.send_and_request}>
                                        {t('send_and_request')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink href={routes.pay_bills}>{t('pay_bills')}</NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className={styles.right}>
                        <Button className={styles.language} onClick={handleChangeLanguage}>
                            <Typography
                                className={i18n.language === 'en' ? styles.isActive : ''}
                                variant="body2">
                                EN
                            </Typography>
                            |
                            <Typography
                                className={i18n.language === 'fr' ? styles.isActive : ''}
                                variant="body2">
                                FR
                            </Typography>
                        </Button>
                        <div className={styles.notifications_wrapper}>
                            <Button
                                variant="icon"
                                className={styles.notification}
                                notify={!!notifications?.find((item) => !item.isRead)}
                                notifyCount={notifications?.filter((item) => !item.isRead).length}
                                onClick={toggleNotifications}>
                                <Icon name="notification" />
                            </Button>
                        </div>
                        <div className={styles.profile_wrapper} ref={profileRef}>
                            <div className={styles.avatar_wrapper} onClick={toggleUserProfile}>
                                {user?.profile?.avatar ? (
                                    <img
                                        className={styles.avatar}
                                        src={user?.profile?.avatar}
                                        alt={`${user?.profile?.firstName} avatar`}
                                    />
                                ) : (
                                    <div className={styles.logo_symbols}>
                                        {getFirstLetter(user?.profile?.firstName)}
                                        {getFirstLetter(user?.profile?.lastName)}
                                    </div>
                                )}
                            </div>
                            <div>
                                <button
                                    className={styles.profile_button}
                                    onClick={toggleUserProfile}>
                                    <Typography variant="body2">
                                        {user?.profile?.firstName} {user?.profile?.lastName}
                                    </Typography>
                                    <Icon name="nav_down" />
                                </button>
                                <div
                                    className={cx([
                                        styles.profile_dropdown,
                                        { isOpen: isOpenProfile }
                                    ])}>
                                    <Typography className={styles.name} variant="h5">
                                        {user?.profile?.firstName} {user?.profile?.lastName}
                                    </Typography>
                                    <Link className={styles.action} to={routes.settings}>
                                        <Typography variant="body3">{t('settings')}</Typography>
                                    </Link>
                                    <Link
                                        to={routes.signin}
                                        className={styles.action}
                                        onClick={handleLogout}>
                                        <Typography variant="body3">{t('logout')}</Typography>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {(user?.status === EUserStatus.UNVERIFIED || user?.status === EUserStatus.PENDING) && (
                <div className={styles.kyc_wrapper}>
                    <div className={styles.container}>
                        <Typography variant="body2">
                            {t(
                                user?.status === EUserStatus.PENDING
                                    ? 'verification_pending'
                                    : 'verification'
                            )}
                        </Typography>
                        {user?.status === EUserStatus.UNVERIFIED && (
                            <Button
                                onClick={handleOpenKycFirstModal}
                                size="small"
                                variant="primary">
                                {submitLocale('verify')}
                            </Button>
                        )}
                    </div>
                </div>
            )}
            <NotificationsSidebar
                isOpen={isOpenNotifications}
                onClose={() => setIsOpenNotifications(false)}
            />
        </>
    );
};

export default Header;
