import React, { useState } from 'react';
import styles from '@layouts/settings/profile/styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import FormField from '@components/form_field';
import DatePicker from '@components/date_picker';
import { capitalizeFirstLetter, getBirthDisabledDates } from '@utils/index';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import Contacts from '@sections/settings/contacts';
import { useFormContext } from 'react-hook-form';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiUserService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';
import { occupationsList } from '@_mock/index';
import Select from '@components/common/select';
import { EUserStatus } from '@xeppt/xeppt-sdk/types';
import Button from '@components/common/button';

const PersonalSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'settings.contact'
    });
    const { labelLocale, submitLocale, requestErrorLocale, requestSuccessLocale } = useLocales();
    const form = useFormContext();
    const [isEdit, setIsEdit] = useState(false);
    const { refetchUser, user } = useUserContext();

    const { handleRequest: handleUpdateUser } = useApiMutation({
        method: () => {
            const {
                profile: { firstName, lastName, birthDate, occupancy }
            } = form.getValues();
            return apiUserService.updateProfile({
                firstName,
                lastName,
                birthDate,
                occupancy
            });
        },
        onSuccess: () => {
            requestSuccessLocale('update_profile');
            refetchUser();
            setIsEdit(false);
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        if (!isEdit) {
            setIsEdit(true);
        } else {
            form.trigger().then((isValid) => {
                isValid && handleUpdateUser(undefined);
            });
        }
    };

    return (
        <div className={styles.row}>
            <div className={styles.header}>
                <Typography variant="body1" lh="120%" weight="semibold">
                    {t('title')}
                </Typography>
                {user?.status === EUserStatus.UNVERIFIED && (
                    <Button
                        leftIcon={isEdit ? undefined : 'edit'}
                        variant="outlined"
                        size="normal"
                        onClick={onSubmit}>
                        {isEdit ? submitLocale('save') : submitLocale('edit')}
                    </Button>
                )}
            </div>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="profile.firstName"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('first_name')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="profile.lastName"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('last_name')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="profile.birthDate"
                        renderComponent={(props) => (
                            <DatePicker
                                {...props}
                                label={labelLocale('birth_date')}
                                isBirthday
                                full
                                // readOnly
                                readOnly={!isEdit}
                                maxDate={getBirthDisabledDates()}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField<string | number>
                        name="profile.occupancy"
                        renderComponent={(props) => (
                            <Select
                                {...props}
                                full
                                isAllowSearch
                                readOnly={!isEdit}
                                label={labelLocale('occupation')}
                                items={occupationsList.map((item) => ({
                                    value: capitalizeFirstLetter(item),
                                    label: capitalizeFirstLetter(item)
                                }))}
                            />
                        )}
                    />
                </div>
                <Contacts />
            </div>
        </div>
    );
};

export default PersonalSection;
