import React from 'react';
import styles from '@layouts/settings/profile/styles.module.scss';
import Skeleton from '@components/common/skeleton';
import Phone from '@components/phone';
import Radio from '@components/common/radio';
import Button from '@components/common/button';
import Input from '@components/common/input';
import { useSettingsModals } from '@hooks/modals/useSettingsModals';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiUserService } from '@api';
import { useTranslation } from 'react-i18next';
import { ENewNotificationType } from '@enum';

const Contacts = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'settings'
    });
    const {
        handleOpenAddEmailModal,
        handleOpenAddPhoneModal,
        handleDeleteEmail,
        handleDeletePhone,
        handleSetPrimaryEmail,
        handleSetPrimaryPhone
    } = useSettingsModals();
    const { labelLocale } = useLocales();

    const {
        data: phones,
        handleRequest: refetchPhones,
        isLoading: isPhoneLoading
    } = useApiQuery({
        method: () => apiUserService.getPhones(),
        notificationType: ENewNotificationType.PHONE
    });

    const {
        data: emails,
        handleRequest: refetchEmails,
        isLoading: isEmailLoading
    } = useApiQuery({
        method: () => apiUserService.getEmails(),
        notificationType: ENewNotificationType.EMAIL
    });

    return (
        <div className={styles.phones_emails_wrapper}>
            <div className={styles.row}>
                {isPhoneLoading && (
                    <Skeleton className={`${styles.phone_skeleton} ${styles.input_wrapper}`} />
                )}
                {phones &&
                    phones
                        .sort((a) => (a.isPrimary ? -1 : 1))
                        .map((item, i) => (
                            <div key={item.id} className={styles.phone_email_row}>
                                <div className={styles.input_wrapper}>
                                    <Phone
                                        key={item.id}
                                        readOnly
                                        isForm={false}
                                        phoneCode={item.phoneCode}
                                        phoneNumber={item.phoneNumber}
                                        onCodeChange={() => {}}
                                        onPhoneChange={() => {}}
                                        label={labelLocale('phone_number')}
                                    />
                                    <div
                                        className={styles.footer_wrapper}
                                        onClick={() => {
                                            if (!item.isPrimary) {
                                                handleSetPrimaryPhone(item, refetchPhones);
                                            }
                                        }}>
                                        <Radio small black checked={item.isPrimary} />
                                        {t('primary_phone')}
                                    </div>
                                </div>
                                <div className={styles.phone_email_actions}>
                                    {i === phones.length - 1 && (
                                        <Button
                                            leftIcon="plus"
                                            variant="outlined"
                                            size="small"
                                            onClick={() => handleOpenAddPhoneModal(refetchPhones)}
                                        />
                                    )}
                                    {!item.isPrimary && (
                                        <Button
                                            leftIcon="delete"
                                            variant="outlined-red"
                                            size="small"
                                            onClick={() => handleDeletePhone(item, refetchPhones)}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
            </div>
            <div className={styles.row}>
                {isEmailLoading && (
                    <Skeleton className={`${styles.email_skeleton} ${styles.input_wrapper}`} />
                )}
                {emails &&
                    emails
                        .sort((a) => (a.isPrimary ? -1 : 1))
                        .map((item, i) => (
                            <div
                                key={item.id}
                                className={`${styles.phone_email_row} ${emails.length > 1 ? styles.email : styles.not_email}`}>
                                <div className={styles.input_wrapper}>
                                    <Input
                                        label={labelLocale('email')}
                                        value={item.email}
                                        full
                                        readOnly
                                    />
                                    <div
                                        className={styles.footer_wrapper}
                                        onClick={() => {
                                            if (!item.isPrimary) {
                                                handleSetPrimaryEmail(item, refetchEmails);
                                            }
                                        }}>
                                        <Radio small black checked={item.isPrimary} />
                                        {t('primary_email')}
                                    </div>
                                </div>
                                <div className={styles.phone_email_actions}>
                                    {i === emails.length - 1 && (
                                        <Button
                                            leftIcon="plus"
                                            variant="outlined"
                                            size="small"
                                            onClick={() => handleOpenAddEmailModal(refetchEmails)}
                                        />
                                    )}
                                    {!item.isPrimary && (
                                        <Button
                                            leftIcon="delete"
                                            variant="outlined-red"
                                            size="small"
                                            onClick={() => handleDeleteEmail(item, refetchEmails)}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
            </div>
        </div>
    );
};

export default Contacts;
