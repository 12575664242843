import React from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext } from 'react-hook-form';
import FullAddress from '@components/address';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { TRegisterIndividualUser } from '@xeppt/xeppt-sdk/types/registration';
import { apiRegistrationService } from '@api';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { Country } from 'country-state-city';
import { useApiMutation } from '@hooks/api/useApiMutation';

const BillingSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.billing'
    });
    const { submitLocale, requestSuccessLocale, requestErrorLocale } = useLocales();
    const { nextStep, prevStep } = useSignupContext();
    const { getValues, trigger } = useFormContext<TRegisterIndividualUser>();

    const { handleRequest: handleRegister, isLoading } = useApiMutation({
        method: () => {
            const values = getValues();
            return apiRegistrationService.registerIndividual({
                ...values,
                billingAddress: {
                    ...values.billingAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === values.billingAddress.country
                        )?.isoCode || values.billingAddress.country
                }
            });
        },
        onSuccess: () => {
            requestSuccessLocale('registration');
            nextStep();
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                handleRegister(undefined);
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <FullAddress name="billingAddress" />
                <div className={styles.actions}>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        size="normal"
                        variant="outlined-dark"
                        onClick={prevStep}>
                        {submitLocale('back')}
                    </Button>
                    <Button
                        className={styles.next}
                        onClick={onSubmit}
                        isLoading={isLoading}
                        size="normal"
                        variant="primary">
                        Complete
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default BillingSection;
