import React from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import styles from './styles.module.scss';
import useModalContext from '@hooks/context/useModalContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { EContactType } from '@xeppt/xeppt-sdk/types';
import { ESendRequestMoneyMethod } from '@enum';

const ContactPaymentModal = () => {
    const { modalData, onClose } = useModalContext();
    const navigate = useNavigate();
    const onClickSend = () => {
        if (modalData?.type === EContactType.INTERAC) {
            navigate(
                `${routes.send_and_request_send}?type=${ESendRequestMoneyMethod.E_TRANSFER}&contactId=${modalData?.id}`
            );
        } else {
            navigate(
                `${routes.send_and_request_send}?type=${ESendRequestMoneyMethod.INTERNAL}&contactId=${modalData?.id}`
            );
        }
        onClose();
    };

    const onClickRequest = () => {
        if (modalData?.type === EContactType.INTERAC) {
            navigate(
                `${routes.send_and_request_request}?type=${ESendRequestMoneyMethod.E_TRANSFER_REQUEST}&contactId=${modalData?.id}`
            );
        } else {
            navigate(
                `${routes.send_and_request_request}?type=${ESendRequestMoneyMethod.INTERNAL_REQUEST}&contactId=${modalData?.id}`
            );
        }
        onClose();
    };

    return (
        <div className={styles.wrapper}>
            <Typography variant="h5" className={styles.title}>
                {modalData?.firstName} {modalData?.lastName}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {modalData?.email || modalData?.phone}
            </Typography>
            <div className={styles.items_wrapper}>
                <div className={styles.item} onClick={onClickSend}>
                    <div className={styles.icon}>
                        <Icon name="send" />
                    </div>
                    <p className={styles.item_title}>
                        Send <br /> money
                    </p>
                </div>
                <div className={styles.item} onClick={onClickRequest}>
                    <div className={styles.icon}>
                        <Icon name="money" />
                    </div>
                    <p className={styles.item_title}>
                        Request <br /> money
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContactPaymentModal;
