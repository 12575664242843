import React, { FC } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { TCard } from '@xeppt/xeppt-sdk/types';
import { hideCardNumber } from '@utils/index';

interface IProps {
    type: 'pay' | 'redeem';
    card: TCard;
}

const SummarySection: FC<IProps> = ({ type, card }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'cashback'
    });
    return (
        <>
            <div className={styles.note}>
                <Icon name="error_filled" width={18} height={18} />
                {t(type === 'pay' ? 'note_2_pay' : 'note_2_redeem')}
            </div>
            <div className={styles.summary}>
                <Typography className={styles.title}>{t('summary')}</Typography>
                <div className={styles.summary_line}>
                    <div>{t('from')}</div>
                    <div>{t(type === 'pay' ? 'title_pay' : 'title_redeem')}</div>
                </div>
                <div className={styles.summary_line}>
                    <div>{t('amount_summary')}</div>
                    <div>$100.00 CAD</div>
                </div>
                <div className={styles.summary_line}>
                    <div>{t('to')}</div>
                    <div>
                        {t(type === 'pay' ? 'pay_to' : 'redeem_to')} (
                        {hideCardNumber(card.cardNumber)})
                    </div>
                </div>
                <div className={styles.summary_line}>
                    <div>{t('date')}</div>
                    <div>{moment().format('MMM D, YYYY')}</div>
                </div>
            </div>
        </>
    );
};

export default SummarySection;
